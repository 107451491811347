<!--机构治理报告左侧历史记录列表-->
<template>
  <el-drawer
    v-if="drawer"
    title="历史报告"
    custom-class="history-drawer"
    :visible.sync="drawer"
    direction="ltr"
    size="360px"
    :before-close="onClose">
    <ul class="history-list-ul">
      <li style="margin-bottom: 10px;">
        <!-- <el-date-picker
          v-model="queryObj.startTime"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="请选择申请日期"
          @change="onSearch">
        </el-date-picker> -->
        <el-form :model="queryObj" :inline="false" label-width="82px" label-position="left">
          <el-form-item label="申请时间：">
            <el-date-picker
              v-model="queryObj.timeArr"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="onSearch">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </li>
      <li v-for="item in creatingList" :key="item.applyId" class="history-list-li">
        <!--报告生成中-->
        <div class="border-radius-half">
          <p>{{ item.status | statusShow }}</p>
        </div>
        <div class="history-list-li-time-wrap">
          <!-- <h4>
            <svg-icon icon-class="shijian" />
            <span>{{ item.startTime }}</span>
          </h4> -->
          <h4 class="fabu">
            <!-- <svg-icon icon-class="shijian" /> -->
            <div class="creating-list-icon history-list-icon create-time-icon">
              <svg-icon icon-class="createTime" class="babu-svg" />
            </div>
            <span class="history-list-text">{{ item.startTime }}</span>
          </h4>
          <!-- <h4 class="fabu">
            <div class="creating-list-icon history-list-icon">
              <svg-icon icon-class="fabu2" class="babu-svg" />
            </div>
            <span class="history-list-text">{{ item.endTime }}</span>
          </h4> -->
        </div>
      </li>
      <li style="color: #10BA7B;text-align: center;">
        <span>已发布报告</span>
        <svg-icon icon-class="gesture-down"/>
      </li>
      <li v-for="item in publishedList" :key="item.applyId" class="history-list-li" @click="onSelect(item)">
        <!--报告生成中-->
        <div v-if="item.status === '1'">
          <p>{{ item.status | statusShow }}</p>
        </div>
        <!--已发布-->
        <div
          v-else
          :id="item.applyId + 'ring'"
          style="width: 70px;height:70px;"
          class="publish-list-l publish-list-ring"></div>
        <div class="publish-list-r history-list-li-time-wrap">
          <h4 class="fabu">
            <div class="publish-list-icon history-list-icon">
              <svg-icon icon-class="fabu2" class="babu-svg" />
            </div>
            <span class="history-list-text">{{ item.endTime }}</span>
          </h4>
          <h4 class="fabu">
            <!-- <svg-icon icon-class="shijian" /> -->
            <div class="publish-list-icon history-list-icon create-time-icon">
              <svg-icon icon-class="createTime" class="babu-svg" />
            </div>
            <span class="history-list-text">{{ item.startTime }}</span>
          </h4>
        </div>
      </li>
      <li v-if="publishedList.length === 0" style="position: absolute;top: 50%;left: 50%;transform: translateX(-50%);">暂无报告</li>
    </ul>
  </el-drawer>
</template>
<script>
import {
  HISTORY_LIST,
  HISTORY_QUERY_OBJ
} from '@/utils/dm/institutionGovernanceReport/data'
import { getHistoryListApi } from '@/api/dm/institutionGovernanceReport/index'
import {
  getPlatformHistoryListApi
} from '@/api/dm/platformGovernanceReport'
// import {
//   buildReportPieChart
// } from '@/utils/pieChart.js'
export default {
  props: {
    applyId: {
      type: Number
    },
    dataFrom: {
      type: String,
      // self(机构本身),other(来源于平台)
      default: 'self'
    }
  },
  filters: {
    statusShow(status) {
      if (Number(status) === 2) {
        return '已发布'
      } else if (Number(status) === 1) {
        return '生成中'
      }
    }
  },
  data() {
    return {
      drawer: false,
      historyList: [],
      queryObj: this.$deepClone(HISTORY_QUERY_OBJ),
      ringChar: []
    }
  },
  computed: {
    creatingList() {
      // 生成中
      let arr = []
      for (let item of this.historyList) {
        if (Number(item.status) === 1) {
          arr.push(item)
        }
      }
      return arr
    },
    publishedList() {
      // 已发布
      let arr = []
      for (let item of this.historyList) {
        if (Number(item.status) === 2) {
          arr.push(item)
        }
      }
      return arr
    }
  },
  watch: {
    async drawer(val, oldVal) {
      if (val) {
        // await this.fetchData()
        this.$nextTick(() => {
          this.drawer && this.publishedList.forEach(item => {
            this.createRingChart(item)
          })
        })
      }
    },
    historyList(val, oldVal) {
      if (this.drawer) {
        this.$nextTick(() => {
          this.drawer && this.publishedList.forEach(item => {
            this.createRingChart(item)
          })
        })
      }
    }
  },
  async created() {
    
  },
  async mounted() {
    await this.fetchData()
    this.$nextTick(() => {
      this.ringChar = []
      this.drawer && this.publishedList.forEach(item => {
        this.createRingChart(item)
      })
    })
   /*  window.onresize = function () {
      for (let ring of this.ringChar) {
        ring.resize()
      }
    } */
    window.addEventListener('resize', () => {
      for (let ring of this.ringChar) {
        ring.resize()
      }
    })
  },
  methods: {
    onClose() {
      this.drawer = false
    },
    async fetchData() {
      let res = null
      if (this.dataFrom === 'self') {
      // 机构本身应运场景
        res = await getHistoryListApi(this.queryObj)
      } else if (this.dataFrom === 'other') {
        // 平台应用场景
        if (this.applyId) {
          this.queryObj.applyId = this.applyId
        }
        res = await getPlatformHistoryListApi(this.queryObj)
      }
      if (res.code === 200) {
        this.historyList = res.data.rows || []
        // this.historyList = this.$deepClone(HISTORY_LIST)
      }
    },
    // 搜索
    async onSearch() {
      // this.queryObj.endTime = this.queryObj.startTime
      const { timeArr } = this.queryObj
      this.queryObj.startTime = timeArr && timeArr.length === 2 ? timeArr[0] : ''
      this.queryObj.endTime = timeArr && timeArr.length === 2 ? timeArr[1] : ''
      await this.fetchData()
    },
    async createRingChart(item) {
      let option = this.buildReportPieChart([item.healthIndexScore])
      let chartDom = this.$echarts.init(document.getElementById(`${item.applyId}ring`))
      this.ringChar.push(chartDom)
      chartDom.setOption(option)
    },
    buildReportPieChart(getvalue) {
      let option = {
        title: {
          text: getvalue + '分',
          textStyle: {
            color: '#666',
            fontSize: 12,
            fontWeight: 400
          },
          itemGap: 10,
          left: 'center',
          top: '40%'
        },
        tooltip: {
          show: false
        },
        angleAxis: { // 极坐标系的角度轴
          max: 100,
          clockwise: true, // 逆时针
          // 隐藏刻度线
          show: false
        },
        radiusAxis: { // 极坐标系的径向轴
          type: 'category',
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false
          },
        },
        polar: {
          center: ['50%', '50%'],
          radius: '160%' //图形大小
        },
        series: [{
          type: 'bar',
          data: getvalue,
          showBackground: true,
          backgroundStyle: {
            color: '#E0E0E0' // 环形图灰色部分（环形图1-value部分）
          },
          coordinateSystem: 'polar',
          roundCap: true,
          barWidth: 6,
          itemStyle: {
            normal: {
              opacity: 1,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#5BAEF8'
                },
                {
                  offset: 1,
                  color: '#0079F6'
                }
              ])
            }
          }
        }]
      }
      return option
    },
    onSelect(item) {
      this.drawer = false
      this.$emit('changeVersion', item.applyId)
    }
  }
}
</script>
<style lang="scss" scoped>
  ul, li, p, h3, h4 {
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: normal;
  }
  .border-radius-half {
    width: 60px;
    height: 60px;
    background: linear-gradient(180deg, #5BAEF8, #0079F6);
    border: 2px solid #C8D5FA;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 52px;
    p {
      height: 100%;
    }
  }
  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100% !important;
  }
  .el-drawer__wrapper{
    ::v-deep.el-drawer__container.el-drawer__open {
      .el-drawer.ltr {
        .el-drawer__header {
          background-color: #E9EEFB !important;
          margin-bottom: 0 !important;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          text-align: center;
          height: 40px;
          line-height: 40px;
          padding: 0 !important;
        }
        .el-drawer__body {
          overflow: auto;
          .history-list-ul {
            font-size: 14px;
            padding: 10px 10px 10px;
            height: 100%;
            position: relative;
            .history-list-li {
              display: flex;
              justify-content: space-around;
              align-items: center;
              background: #FFFFFF;
              border: 1px solid #C8D5FA;
              border-radius: 4px;
              margin-bottom: 10px;
              padding: 5px 0;
              &:last-child {
                margin-bottom: 0;
              }
              &:hover {
                border: 1px solid #638BF7;
                box-shadow: 0px 0px 6px 0px rgba(43, 96, 248, 0.8);
              }
              .history-list-li-time-wrap {
                .fabu {
                  .history-list-icon {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background-color: #D9E7FC;
                    position: relative;
                    vertical-align: middle;
                    margin-right: 5px;
                    &.create-time-icon {
                      background-color: #FFEACC;
                    }
                    .svg-icon {
                      position: absolute;
                      left: 50%;
                      top: 50%;
                      transform: translate(-50%, -50%);
                    }
                  }
                  .history-list-text {
                    vertical-align: middle;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  
</style>
