<!--机构治理报告匹配项-->
<template>
  <el-row class="match-item-wrap">
    <el-col :span="8">
      <div class="no-match-l title">
        <span class="title-header"></span>
        <h4 class="title-text">
          风险等级
        </h4>
      </div>
      <!-- <div id="pie-chart-two" style="width: 100%;height: 200px;"></div> -->
      <div id="pie-chart-three" style="width: 100%;height: 200px;"></div>
    </el-col>
    <el-col :span="8">
      <div class="no-match-l title">
        <span class="title-header"></span>
        <h4 class="title-text">
          问题类型
        </h4>
      </div>
      <!-- <div id="pie-chart-three" style="width: 100%;height: 200px;"></div> -->
      <div id="pie-chart-two" style="width: 100%;height: 200px;"></div>
    </el-col>
    <el-col :span="8">
      <div class="no-match-l title" style="display:inline-block">
        <span class="title-header"></span>
        <h4 class="title-text">
          字段错误
        </h4>
      </div>
      <!-- <section class="apply-report-section" style="display: inline-block;position: absolute;right: 10px;">
        <el-button type="primary" size="small">申请治理报告</el-button>
      </section> -->
      <div id="pie-chart-four" style="width: 100%;height: 200px;"></div>
    </el-col>
  </el-row>
  <!-- <section>
    <header>
      <el-row>
        <el-col :span="7">
          <div class="no-match-l title">
            <span class="title-header"></span>
            <h4 class="title-text">
              风险等级
            </h4>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="no-match-l title">
            <span class="title-header"></span>
            <h4 class="title-text">
              风险等级
            </h4>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="no-match-l title">
            <span class="title-header"></span>
            <h4 class="title-text">
              风险等级
            </h4>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="no-match-l title">
            <span class="title-header"></span>
            <h4 class="title-text">
              风险等级
            </h4>
          </div>
        </el-col>
      </el-row>
    </header>
    <el-row class="match-item-wrap">
      <el-col :span="8">
        <div id="pie-chart-two" style="width: 100%;height: 200px;"></div>
      </el-col>
      <el-col :span="8">
        <div id="pie-chart-three" style="width: 100%;height: 200px;"></div>
      </el-col>
      <el-col :span="8">
        <div id="pie-chart-four" style="width: 100%;height: 200px;"></div>
      </el-col>
    </el-row>
  </section> -->
</template>
<script>
import {
  getMatchItemApi
} from '@/api/dm/institutionGovernanceReport'
import {
  chartsDataHandle
} from '@/utils/dm/institutionGovernanceReport/handle.js'
export default {
  props: {
    applyId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      chartsDataObj: {
        matchResult: [],
        problemItemResult: [],
        riskLevelResult: [],
        fieldProblemResult: []
      },
    }
  },
  watch: {
    async applyId(val) {
      if (val) {
        await this.commonBuildRingChart()
      }
    }
  },
  async mounted() {
    if (this.applyId) {
      await this.commonBuildRingChart()
    }
  },
  methods: {
    // 图表创建
    async commonBuildRingChart() {
      await this.fetchChartsData(this.applyId)
      const data2 = chartsDataHandle(this.chartsDataObj.problemItemResult)
      let legendData2 = data2.legendData
      let seriesData2 = data2.seriesData
      const color3 = ['#E66E52', '#F7C122', '#63DAAB']
      const data3 = chartsDataHandle(this.chartsDataObj.riskLevelResult)
      let legendData3 = data3.legendData
      let seriesData3 = data3.seriesData
      const color2 = ['#5B8FF9', '#63DAAB', '#945FB9', '#E66E52', '#F7C122']
      const data4 = chartsDataHandle(this.chartsDataObj.fieldProblemResult)
      let legendData4 = data4.legendData
      let seriesData4 = data4.seriesData
      const color4 = ['#5B8FF9', '#63DAAB', '#5D7092', '#E66E52', '#F7C122', '#945FB9']
      this.createPieChart('pie-chart-two', color2, legendData2, seriesData2, true)
      this.createPieChart('pie-chart-three', color3, legendData3, seriesData3, true)
      this.createPieChart('pie-chart-four', color4, legendData4, seriesData4, true)
    },
    // 创建饼图的公共方法
    createPieChart(chart, color, legendData, seriesData, isRing = false) {
      let legendItemArr = []
      let radius = '50%'
      if (isRing) {
        // 环形图
        radius = ['35%', '65%'] //饼图的半径，第一个为内半径，第二个为外半径
      }
      if (legendData.length > 6) {
        legendData = legendData.splice(0, 6)
      }
      let pie = this.$echarts.init(document.getElementById(chart))
      let data = this.buildData()
      let option = {
        backgroundColor: '#fff',
        title: {
          show: false,
          text: '销售量统计',
          x: 'center'
        },
        color,
        tooltip: {
          trigger: 'item',
          backgroundColor: '#fff',
          extraCssText: 'box-shadow: 0px 0px 12px 0px rgba(110, 110, 110, 0.6); border-radius: 4px;',
          textStyle: {
            color: '#333'
          },
          formatter: "{a} <br/>{b}  {c} ({d}%)"
        },
        legend: {
          show: true,
          orient: 'vertical',
          top: 'center',
          left: '65%',
          data: legendData,
          formatter: function (name) {
            return (name.length > 4 ? (name.slice(0, 4) + "...") : name);
          }
        },
        series: [{
          // name: '星期',
          name: '',
          type: 'pie',
          radius,
          center: ['35%', '50%'],
          data: seriesData,
          label: {
            show: false,
            position: 'inside',
            formatter: function (p) {   //指示线对应文字,百分比
              return p.percent + "%"
            }
          },
          itemStyle: {
            borderWidth: 1, //设置border的宽度有多大
            borderColor:'#fff',
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              backgroundColor: '#fff',
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
        }]
      }
      pie.setOption(option)
      pie.on('click', function(params) {
        console.log(params.name, params.value, params.percent, params.seriesName, params.seriesType)
      })
      window.addEventListener('resize', () => {
        pie.resize()
      })
      // window.onresize = function () {
      //   pie.resize()
      // }
    },
    // 获取图表数据
    async fetchChartsData() {
      let res = await getMatchItemApi(this.applyId)
      if (res.code === 200) {
        this.chartsDataObj.matchResult = res.data.matchResult
        this.chartsDataObj.problemItemResult = res.data.problemItemResult
        this.chartsDataObj.riskLevelResult = res.data.riskLevelResult
        this.chartsDataObj.fieldProblemResult = res.data.fieldProblemResult
        this.$emit('matchSitutionsObj', res.data)
      }
    },
    buildData() {
      let labels = ['匹配项', '未匹配项']
      let values = []
      for (let i = 0; i < labels.length; i++) {
        values.push({
          value: parseInt(Math.random() * 10000),
          name: labels[i],
          color: this.randomColor()
        })
      }
      return {
        labels,
        values
      }
    },
    randomColor() {
      var r = Math.floor(Math.random() * 256)
      var g = Math.floor(Math.random() * 256)
      var b = Math.floor(Math.random() * 256)
      var color = '#' + r.toString(16) + g.toString(16) + b.toString(16)
      return color
    },
  }
}
</script>
<style lang="scss" scoped>
.match-item-wrap {
  padding: 10px 10px 0 10px;
  position: relative;
  .no-match-l {
    width: 80px;
    .title-header {
      display: inline-block;
      width: 4px;
      height: 16px;
      /* background: #A9BBFF; */
      background-color: #0073E9;
      border-radius: 2px;
      vertical-align: top;
    }
    .title-text {
      display: inline-block;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 18px;
      margin-left: 5px;
    }
  }
}
</style>
