// 治理报告未匹配项table
import store from '@/store'
const { provinceCode } = store.getters.dept
let showShangHai = false
if (provinceCode === '310000') {
  showShangHai = true
} else {
  showShangHai = false
}
export const COLUMN_LIST = [
  {
    id: '1',
    rank: 1,
    label: '药品编码',
    prop: 'drugId',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: true,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  // {
  //   id: '30',
  //   rank: 30,
  //   label: '国家贯标码',
  //   prop: 'nhsaCode',
  //   sortable: false,
  //   isSplited: false, // 该列是否是由多个字段拼接
  //   show: true,
  //   fixed: true,
  //   width: '100',
  //   minWidth: '',
  //   align: 'center'
  // },
  {
    id: '3',
    rank: 3,
    label: '药品通用名',
    prop: 'genericName',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '150',
    minWidth: '',
    align: 'center'
  },
  {
    id: '6',
    rank: 6,
    prop: 'tradeName',
    label: '药品商品名',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '120',
    minWidth: '120',
    align: 'center'
  },
  {
    id: '7',
    rank: 7,
    label: '药品规格',
    prop: 'specText',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  
  {
    id: '15',
    rank: 15,
    label: '包装单位',
    prop: 'packUnit',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '16',
    rank: 16,
    label: '包装系数',
    prop: 'packFactor',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '12',
    rank: 12,
    label: '最小单位',
    prop: 'minPackUnit',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '13',
    rank: 13,
    label: '规格单位',
    prop: 'doseUnit',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '14',
    rank: 14,
    label: '规格系数',
    prop: 'doseFactor',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  
  {
    id: '8',
    rank: 8,
    label: '剂型代码',
    prop: 'formCode',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '9',
    rank: 9,
    label: '剂型名称',
    prop: 'formName',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '17',
    rank: 17,
    label: '厂家代码',
    prop: 'manufacturerCode',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '18',
    rank: 18,
    label: '厂家名称',
    prop: 'manufacturerName',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '19',
    rank: 19,
    label: '批准文号',
    prop: 'approvalNo',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '20',
    rank: 20,
    label: '国基标志',
    prop: 'nedFlag',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '21',
    rank: 21,
    label: '上基标志',
    prop: 'shNedFlag',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: showShangHai,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '22',
    rank: 22,
    label: '高危药品分级代码',
    prop: 'highRiskLvCode',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '120',
    minWidth: '',
    align: 'center'
  },
  {
    id: '23',
    rank: 23,
    label: '高危药品分级名称',
    prop: 'highRiskLvName',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '120',
    minWidth: '',
    align: 'center'
  },
  {
    id: '24',
    rank: 24,
    label: '抗菌药分级代码',
    prop: 'antibioticLvCode',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '25',
    rank: 25,
    label: '抗菌药分级名称',
    prop: 'antibioticLvName',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '26',
    rank: 26,
    label: '精麻毒放标志代码',
    prop: 'toxicNarcoticTypeCode',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '120',
    minWidth: '',
    align: 'center'
  },
  {
    id: '27',
    rank: 27,
    label: '精麻毒放标志名称',
    prop: 'toxicNarcoticTypeName',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '120',
    minWidth: '',
    align: 'center'
  },
  
  {
    id: '4',
    rank: 4,
    label: '中西草生物制品类型代码',
    prop: 'classCode',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '180',
    minWidth: '',
    align: 'center'
  },
  {
    id: '10',
    rank: 10,
    label: '分类代码',
    prop: 'phaClassCode',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '11',
    rank: 11,
    label: '分类名称',
    prop: 'phaClassName',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '28',
    rank: 28,
    label: 'DDD值',
    prop: 'dddValue',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '29',
    rank: 29,
    label: 'DDD单位',
    prop: 'dddUnit',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '5',
    rank: 5,
    label: '中西草生物制品类型名称',
    prop: 'className',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '220',
    minWidth: '',
    align: 'center'
  },
  {
    id: '2',
    rank: 2,
    label: '上海阳光采购平台药品统编码',
    last: true,
    prop: 'ustdCode',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: showShangHai,
    fixed: false,
    width: '190',
    minWidth: '170',
    align: 'center'
  },
  {
    id: '31',
    rank: 31,
    label: '国家采集药品标志',
    prop: 'ncpFlag',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
  {
    id: '30',
    rank: 30,
    label: '国家贯标码',
    prop: 'nhsaCode',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '',
    align: 'center'
  },
]
