<!--治理报告放大页面-->
<template>
  <el-container class="layout-container">
    <el-header class="report-table-header clearfix">
      <div class="title-header-container">
        <span class="title-header"></span>
        <h4 class="title-text">
          {{ searchTitle }}
        </h4>
        <svg-icon
          icon-class="wenhao"
          fill-color="#0073E9"
          style="width: 18px;height: 18px;cursor: pointer;position: absolute;top: 50%;transform: translateY(-50%);right: -20px;"
          @click="onShowSource" />
      </div>
      <el-form :model="tableSearchObj" :inline="true" class="">
        <el-form-item v-if="!isSelectedMatch" label="未匹配原因：">
          <el-select v-model="tableSearchObj.errorCodeList" multiple collapse-tags placeholder="请选择">
            <el-option
              v-for="item in errorOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="tableSearchObj.searchValue" clearable placeholder="输入编号/药品通用名" />
        </el-form-item>
        <el-form-item v-if="isSelectedMatch" label="只看问题字段">
          <el-switch
            v-model="tableSearchObj.errorProblemnFlag"
            active-color="#2c5ff9"
            inactive-color="#b5ccf5"
            @change="errorFilterChange">
          </el-switch>
        </el-form-item>
        <el-form-item v-if="isSelectedMatch" label="筛选">
          <el-switch
            v-model="tableSearchObj.isFilter"
            active-color="#2c5ff9"
            inactive-color="#b5ccf5">
          </el-switch>
        </el-form-item>
        <el-form-item v-if="isSelectedMatch">
          <el-button size="small" type="primary" @click="onFilterReset">筛选重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="onSearchReportTable">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-zoom-out" @click="onEnNormal">还原</el-button>
        </el-form-item>
      </el-form>
    </el-header>
    <el-main>
      <no-match-report
        v-if="!isSelectedMatch"
        ref="noMatchTable"
        :search-obj="tableSearchObj"
        :apply-id="applyId"
        @noMatchTable="getNoMatchTable"/>
      <match-report
        v-if="isSelectedMatch && applyId"
        ref="matchTable"
        :search-obj="tableSearchObj"
        :apply-id="applyId"
        @matchTable="getMatchTable"/>
    </el-main>
    <el-footer style="height: 42px;" class="clearfix">
      <div class="fl" style="line-height: 42px;">
        <i class="el-icon-warning" style="color: #94AFF7;vertical-align: middle;"></i>
        <span style="margin-left: 3px;font-size: 12px;color: #999;">系统所展示药品信息，仅供参考，不作为使用依据</span>
      </div>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="tableSearchObj.pageNum"
        :limit.sync="tableSearchObj.pageSize"
        class="fr"
        @pagination="getTableHandle"/>
    </el-footer>
    <source-dialog-vue :dialog-visible.sync="dialogVisible" />
  </el-container>
</template>
<script>
  import NoMatchReport from './NoMatchReportTable' // 未匹配项审核报告模块表格
  import MatchReport from './MatchReportTable' // 匹配项审核报告模块表格
  import SourceDialogVue from './SourceDialog.vue'
  import {
  NO_MATCH_TABLE_SEARCH_OBJ
} from '@/utils/dm/institutionGovernanceReport/data'
  export default {
    props: {
      enlarge: {
        type: Boolean
      },
      /* tableSearchObj: {
        type: Object
      }, */
      isSelectedMatch: {
        type: Boolean
      },
      applyId: {
        type: Number
      },
      errorOptions: {
        type: Array
      },
      searchTitle: {
        type: String
      }
    },
    components: {
      NoMatchReport,
      MatchReport,
      SourceDialogVue
    },
    data() {
      return {
        total: 0,
        dialogVisible: false,
        tableSearchObj: this.$deepClone(NO_MATCH_TABLE_SEARCH_OBJ),
      }
    },
    methods: {
      // 匹配项审核报告模块表格传递的数据
      getMatchTable(data) {
        this.total = data.total
      },
      // 未匹配项审核报告模块表格传递的数据
      getNoMatchTable(data) {
        this.total = data.total
      },
      // 只看问题字段
      async errorFilterChange(val) {
       /*  this.tableSearchObj.errorFilterFlag = val ? '1' : ''
        this.tableSearchObj.pageNum = 1
        await this.getTableHandle() */
        this.$emit('errorFilterChange', val)
      },
      // 点击查询按钮
      async onSearchReportTable() {
        this.tableSearchObj.pageNum = 1
        await this.getTableHandle()
        // this.$emit('onSearchReportTable')
      },
      // 分页查询事件
      async getTableHandle() {
        if (!this.isSelectedMatch) {
          if (this.$refs.noMatchTable) {
            await this.$refs.noMatchTable.fetchData()
          }
        } else {
          if (this.$refs.matchTable) {
            await this.$refs.matchTable.fetchData()
          }
        }
      },
      onEnNormal() {
        // this.enlarge = false
        this.$emit('update:enlarge', false)
      },
      // 筛选重置
      onFilterReset() {
        if (this.tableSearchObj.isFilter) {
          this.$refs.matchTable && this.$refs.matchTable.resetFilterHandle()
        }
      },
      // 数据来源弹窗
      onShowSource() {
        this.dialogVisible = true
      }
    }
  }
</script>
<style lang="scss" scoped>
  .el-main {
    padding: 15px;
    padding-top: 0;
  }
  .report-table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ::v-deep td {
      padding: 3px 5px !important;
    }
    .title-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      position: relative;
      .title-header {
        display: inline-block;
        width: 4px;
        height: 18px;
        background: #0073E9;
        border-radius: 2px;
        vertical-align: middle;
      }
      .title-text {
        display: inline-block;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        margin-left: 5px;
        vertical-align: middle;
      }
    }
    .el-form {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      .el-form-item {
        margin-bottom: 0 !important;
      }
    }
  }
</style>