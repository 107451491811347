<!---机构和平台---查看报告的时候显示的未匹配表格-->
<template>
  <el-table
    ref="no-map-table"
    :data="tableData"
    :cell-class-name="noMapReasonHandle"
    :header-cell-style="{textAlign: 'center'}"
    style="width: 100%;"
    height="100%"
    class="no-match-report-table"
    border>
    <el-table-column
      label="未匹配原因"
      fixed
      width="126"
      show-overflow-tooltip>
      <template slot-scope="scope">
        <span v-for="(item, index) in scope.row.mapFailReasonStrList" :key="index">
          {{ item }}
        </span>
        <!-- <span>{{ scope.row.mapFailReasonStrList | noMapReason }}</span> -->
      </template>
    </el-table-column>
    <!-- <el-table-column
      label="药品通用名"
      prop="genericName"/>
    <el-table-column
      label="规格"
      prop="specText"/>
    <el-table-column
      label="剂型名称"
      width="180"
      peop="formName"/>
    <el-table-column
      label="厂商"
      prop="manufacturerName"/>
    <el-table-column
      label="批准文号"
      prop="approvalNo"/>
    <el-table-column
      label="国基标志"
      prop="nedFlag"/> -->
        <template v-for="item in COLUMN_LIST">
          <el-table-column
            v-if="item.show"
            :prop="item.prop"
            :fixed="item.fixed"
            :width="item.width"
            :min-width="item.minWidth"
            :label="item.label"
            :class-name="tableColumnClass(item)"
            show-overflow-tooltip/>
      </template>
  </el-table>
</template>
<script>
  import {
    FILTER_OPTIONS,
    GRADE_OPTIONS,
    TABLE_DATA,
    TABLE_DATA_ORIGIN
  } from '@/utils/dm/institutionGovernanceReport/data'
  import {
    handleTableData
  } from '@/utils/dm/institutionGovernanceReport/handle.js'
  import { getNoMatchRecordListApi } from '@/api/dm/institutionGovernanceReport'
  import {
  COLUMN_LIST
} from '@/utils/dm/institutionGovernanceReport/noMatchTableData'
  export default {
    name: 'institutionNoMatchReport',
    props: {
      searchObj: {
        required: true,
        type: Object
      },
      applyId: {
        type: Number,
        required: true
      }
    },
    filters: {
      noMapReason(reason) {
        if (reason) {
          return reason.join(',')
        }
      }
    },
    computed: {
      noMapReasonList: function () {
        return function (list) {
          if (list && list.length > 1) {
            return [list[0], '...']
          }
        }
      },
      tableColumnClass() {
        return function (item) {
          if (item.last) {
            return 'none-border'
          } else {
            return 'adj'
          }
        }
      },
      // 判断是否是上海的机构
      showShangHai() {
        const { provinceCode } = this.$store.getters.dept
        if (provinceCode === '310000') {
          return true
        } else {
          return false
        }
      }
    },
    watch: {
      searchObj: {
        async handler(newVal, oldVal) {
          // await this.fetchData()
        },
        deep: true
      }
    },
    data() {
      return {
        tableData: [],
        COLUMN_LIST: this.$deepClone(COLUMN_LIST)
      }
    },
    async mounted() {
      this.tableDom = this.$refs['no-map-table'].bodyWrapper
      this.tableDom.addEventListener('scroll', () => {
        this.$refs['no-map-table'] && this.$refs['no-map-table'].doLayout()
      })
      this.$nextTick(() => {
        this.$refs['no-map-table'] && this.$refs['no-map-table'].doLayout()
      })
      setTimeout(()=> {
        this.$refs['no-map-table'].doLayout();
      },500)
    },
    async created() {
      this.searchObj.applyId = this.applyId
      await this.fetchData()
    },
    methods: {
      async fetchData() {
        let res = await getNoMatchRecordListApi(this.searchObj)
        if (res.code === 200) {
          this.tableData = res.data.rows || []
          this.$emit('noMatchTable', res.data)
          this.$nextTick(() => {
            // this.$refs['no-map-table'].$el.style.width = '80%'
            this.$refs['no-map-table'] && this.$refs['no-map-table'].doLayout()
          })
        }
      },
      noMapReasonHandle({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0 && row.mapFailReasonStrList && row.mapFailReasonStrList.length > 0) {
          return 'no-match-reason'
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .el-table--border th.gutter:last-of-type {
    display: block!important;
    width: 17px!important;
  }
  .no-match-report-table {
    /* 解决element-ui的table表格控件表头与内容列不对齐问题 */
    ::v-deep th.gutter:last-of-type {
      display: block!important;
      width: 19px!important;
    }
    ::v-deep td.none-border {
      border-right: none !important;
    }
    ::v-deep.el-table__fixed {
      /* height:auto !important;  */
      height: calc(100% - 12px) !important;
      bottom: 16px;
    }
    ::v-deep .no-match-reason {
      .cell {
        color: #2B60F8;
        text-align: center;
        position: relative;
        height: 30px;
        span {
          display: inline-block;
          padding: 5px 5px !important;
          /* width: 100px; */
          background-color: #D7E3FA;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-63%, -50%);
        }
      }
    }
  }
</style>
  