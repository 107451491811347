var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "soure-data-dialog vertical-middle-dialog",
          attrs: {
            title: "查看数据来源",
            visible: _vm.dialogVisible,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "800px",
            "append-to-body": true,
            center: "",
            "before-close": _vm.handleClose,
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "no-map-table",
              staticClass: "no-match-report-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-cell-style": { textAlign: "center" },
                height: "100%",
                stripe: "",
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "字段名", prop: "fieldName", width: "400" },
              }),
              _c("el-table-column", {
                attrs: { label: "主要参考来源", prop: "mainRefSourece" },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }