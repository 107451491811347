var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "match-item-wrap" },
    [
      _c("el-col", { attrs: { span: 8 } }, [
        _c("div", { staticClass: "no-match-l title" }, [
          _c("span", { staticClass: "title-header" }),
          _c("h4", { staticClass: "title-text" }, [_vm._v(" 风险等级 ")]),
        ]),
        _c("div", {
          staticStyle: { width: "100%", height: "200px" },
          attrs: { id: "pie-chart-three" },
        }),
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("div", { staticClass: "no-match-l title" }, [
          _c("span", { staticClass: "title-header" }),
          _c("h4", { staticClass: "title-text" }, [_vm._v(" 问题类型 ")]),
        ]),
        _c("div", {
          staticStyle: { width: "100%", height: "200px" },
          attrs: { id: "pie-chart-two" },
        }),
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c(
          "div",
          {
            staticClass: "no-match-l title",
            staticStyle: { display: "inline-block" },
          },
          [
            _c("span", { staticClass: "title-header" }),
            _c("h4", { staticClass: "title-text" }, [_vm._v(" 字段错误 ")]),
          ]
        ),
        _c("div", {
          staticStyle: { width: "100%", height: "200px" },
          attrs: { id: "pie-chart-four" },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }