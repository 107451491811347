/**机构数据治理 */
import request from '@/utils/request'

// 发布列表-时间轴
export function getChartsDataApi(query) {
  return request({
    url: '/dm/org/eval/statistic/' + query.applyId,
    method: 'GET'
  })
}
// 治理报告申请 /dm/org/eval/apply
export function applyReportApi() {
  return request({
    url: '/dm/org/eval/apply',
    method: 'POST',
    loading: true
  })
}
// 获取该机构最近一次的申请报告id
export function getLatestRecord() {
  return request({
    url: '/dm/org/eval/max/applyId',
    method: 'get'
  })
}
// 健康指数、雷达分析
export function getScoreApi(applyId) {
  return request({
    url: '/dm/org/eval/health/info/' + applyId,
    method: 'get'
  })
}
// 匹配情况(匹配项、未匹配项)
export function getMatchSituationApi(applyId){
  return request({
    url: '/dm/org/eval/match/chart/' + applyId,
    method: 'get'
  })
}
// 匹配项
export function getMatchItemApi(applyId){
  return request({
    url: '/dm/org/eval/match/statistic/' + applyId,
    method: 'get'
  })
}
// 未匹配原因
export function getNoMatchReasonApi(applyId){
  return request({
    url: '/dm/org/eval/notMatch/statistic/' + applyId,
    method: 'get'
  })
}
// 历史版本
export function getHistoryListApi(data){
  return request({
    url: '/dm/org/eval/record/list',
    method: 'get',
    params: data
  })
}
// 未匹配项中审核报告的分页列表查询
export function getNoMatchRecordListApi(data){
  return request({
    url: '/dm/org/eval/noMatch/record/list',
    method: 'get',
    params: data
  })
}
// 匹配项中审核报告的分页列表查询
export function getMatchRecordListApi(data){
  return request({
    url: '/dm/org/eval/result/list',
    method: 'post',
    data: data
  })
}
