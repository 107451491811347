<!--机构治理报告未匹配项-->
<template>
  <section class="no-match-container">
    <div class="no-match-l title">
      <span class="title-header"></span>
      <h4 class="title-text">
        未匹配原因
      </h4>
    </div>
    <div class="no-match-c no-match-bar-wrap" id="no-match-bar"></div>
    <!-- <div class="no-match-r no-match-text-description">
      <h3>原因说明：</h3>
      <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 9}" class="reason-container" v-model="textarea" />
    </div> -->
  </section>
</template>
<script>
import {
  getNoMatchReasonApi
} from '@/api/dm/institutionGovernanceReport'
import * as echarts from 'echarts'
export default {
  props: {
    applyId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      xAxisData: [],
      seriesData: [],
      textarea: '这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因，这里是原因。'
    }
  },
  async mounted() {
    this.$nextTick(async() => {
      await this.fetchData()
    })
  },
  methods: {
    async fetchData() {
      this.seriesData = []
      this.xAxisData = []
      let res = await getNoMatchReasonApi(this.applyId)
      if (res.code === 200) {
        res.data.forEach(item => {
          this.seriesData.push(item.count)
          this.xAxisData.push(item.name)
          this.bulidBar()
        })
        this.$emit('noMatchObj', res.data)
      }
    },
    bulidBar() {
      let chartDom = document.getElementById('no-match-bar')
      let myChart = echarts.init(chartDom)
      let data = [1000, 1, 2]
      let option = {
        xAxis: {
          type: 'category',
          data: this.xAxisData,
           //坐标轴斜着显示
          axisLabel: {  
            interval:0,  
            rotate:40  
          },
          axisTick: {
            show:false
          },
          axisLine: {show:false}
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value',
          axisTick: {
            show:false
          },
          axisLine: {show:false}
        },
        tooltip: {
          show: true
        },
        series: [
          {
            // data: [120, 200, 150, 80, 70, 110, 130],
            data: this.seriesData,
            // data,
            type: 'bar',
            itemStyle: {
              color: new echarts.graphic.LinearGradient( 0, 1, 0, 0,
                [
                  {offset: 0, color: '#4B56F2'},
                  {offset: 0.5, color: '#9C6AFC'},
                  {offset: 1, color: '#AC3EF2'}
                ]
              ),
              barBorderRadius: [10, 10, 0, 0]
            },
            label: {
              show: true,
              position: 'top',
              distance: 5,
            },
            barWidth: 15
          }
        ]
      }
      option && myChart.setOption(option)
      window.addEventListener('resize', () => {
        myChart.resize()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.no-match-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 0 16px;
  height: 100%;
  .no-match-l {
    width: 80px;
    .title-header {
      display: inline-block;
      width: 4px;
      height: 16px;
      /* background: #A9BBFF; */
      background-color: #0073E9;
      border-radius: 2px;
      vertical-align: top;
    }
    .title-text {
      display: inline-block;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 18px;
      margin-left: 5px;
    }
  }
  .no-match-c {
    flex: 1;
    height: 100%;
  }
  .no-match-r {
    width: 28%;
    h3 {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .reason-container {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      background: #EBF0FA;
      border: 1px solid #AFC8FA;
      border-radius: 6px;
      /* padding: 10px 8px; */
      margin: 10px 0;
      ::v-deep.el-textarea__inner {
        background-color: inherit !important;
      }
    }
  }
}
</style>
