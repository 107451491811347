var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      ref: "no-map-table",
      staticClass: "no-match-report-table",
      staticStyle: { width: "100%" },
      attrs: {
        data: _vm.tableData,
        "cell-class-name": _vm.noMapReasonHandle,
        "header-cell-style": { textAlign: "center" },
        height: "100%",
        border: "",
      },
    },
    [
      _c("el-table-column", {
        attrs: {
          label: "未匹配原因",
          fixed: "",
          width: "126",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return _vm._l(
                scope.row.mapFailReasonStrList,
                function (item, index) {
                  return _c("span", { key: index }, [
                    _vm._v(" " + _vm._s(item) + " "),
                  ])
                }
              )
            },
          },
        ]),
      }),
      _vm._l(_vm.COLUMN_LIST, function (item) {
        return [
          item.show
            ? _c("el-table-column", {
                attrs: {
                  prop: item.prop,
                  fixed: item.fixed,
                  width: item.width,
                  "min-width": item.minWidth,
                  label: item.label,
                  "class-name": _vm.tableColumnClass(item),
                  "show-overflow-tooltip": "",
                },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }