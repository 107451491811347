var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("view-report", {
    attrs: { "data-from": _vm.dataFrom, "apply-id-from-other": _vm.applyId },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }