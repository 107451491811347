var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container" },
    [
      _c(
        "el-header",
        { staticClass: "report-table-header clearfix" },
        [
          _c(
            "div",
            { staticClass: "title-header-container" },
            [
              _c("span", { staticClass: "title-header" }),
              _c("h4", { staticClass: "title-text" }, [
                _vm._v(" " + _vm._s(_vm.searchTitle) + " "),
              ]),
              _c("svg-icon", {
                staticStyle: {
                  width: "18px",
                  height: "18px",
                  cursor: "pointer",
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: "-20px",
                },
                attrs: { "icon-class": "wenhao", "fill-color": "#0073E9" },
                on: { click: _vm.onShowSource },
              }),
            ],
            1
          ),
          _c(
            "el-form",
            { attrs: { model: _vm.tableSearchObj, inline: true } },
            [
              !_vm.isSelectedMatch
                ? _c(
                    "el-form-item",
                    { attrs: { label: "未匹配原因：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            "collapse-tags": "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.tableSearchObj.errorCodeList,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableSearchObj, "errorCodeList", $$v)
                            },
                            expression: "tableSearchObj.errorCodeList",
                          },
                        },
                        _vm._l(_vm.errorOptions, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "输入编号/药品通用名",
                    },
                    model: {
                      value: _vm.tableSearchObj.searchValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableSearchObj, "searchValue", $$v)
                      },
                      expression: "tableSearchObj.searchValue",
                    },
                  }),
                ],
                1
              ),
              _vm.isSelectedMatch
                ? _c(
                    "el-form-item",
                    { attrs: { label: "只看问题字段" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#2c5ff9",
                          "inactive-color": "#b5ccf5",
                        },
                        on: { change: _vm.errorFilterChange },
                        model: {
                          value: _vm.tableSearchObj.errorProblemnFlag,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.tableSearchObj,
                              "errorProblemnFlag",
                              $$v
                            )
                          },
                          expression: "tableSearchObj.errorProblemnFlag",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isSelectedMatch
                ? _c(
                    "el-form-item",
                    { attrs: { label: "筛选" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#2c5ff9",
                          "inactive-color": "#b5ccf5",
                        },
                        model: {
                          value: _vm.tableSearchObj.isFilter,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableSearchObj, "isFilter", $$v)
                          },
                          expression: "tableSearchObj.isFilter",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isSelectedMatch
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.onFilterReset },
                        },
                        [_vm._v("筛选重置")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.onSearchReportTable },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: "el-icon-zoom-out",
                      },
                      on: { click: _vm.onEnNormal },
                    },
                    [_vm._v("还原")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-main",
        [
          !_vm.isSelectedMatch
            ? _c("no-match-report", {
                ref: "noMatchTable",
                attrs: {
                  "search-obj": _vm.tableSearchObj,
                  "apply-id": _vm.applyId,
                },
                on: { noMatchTable: _vm.getNoMatchTable },
              })
            : _vm._e(),
          _vm.isSelectedMatch && _vm.applyId
            ? _c("match-report", {
                ref: "matchTable",
                attrs: {
                  "search-obj": _vm.tableSearchObj,
                  "apply-id": _vm.applyId,
                },
                on: { matchTable: _vm.getMatchTable },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-footer",
        { staticClass: "clearfix", staticStyle: { height: "42px" } },
        [
          _c(
            "div",
            { staticClass: "fl", staticStyle: { "line-height": "42px" } },
            [
              _c("i", {
                staticClass: "el-icon-warning",
                staticStyle: { color: "#94AFF7", "vertical-align": "middle" },
              }),
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-left": "3px",
                    "font-size": "12px",
                    color: "#999",
                  },
                },
                [_vm._v("系统所展示药品信息，仅供参考，不作为使用依据")]
              ),
            ]
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            staticClass: "fr",
            attrs: {
              total: _vm.total,
              page: _vm.tableSearchObj.pageNum,
              limit: _vm.tableSearchObj.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.tableSearchObj, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.tableSearchObj, "pageSize", $event)
              },
              pagination: _vm.getTableHandle,
            },
          }),
        ],
        1
      ),
      _c("source-dialog-vue", {
        attrs: { "dialog-visible": _vm.dialogVisible },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.dialogVisible = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }