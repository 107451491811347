var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "win-table",
    {
      ref: "matchReportTableRef",
      staticClass: "match-report-table",
      staticStyle: { width: "100%" },
      attrs: {
        data: _vm.tableData,
        "span-method": _vm.getSummaries,
        "header-cell-class-name": _vm.headerGradeInfo,
        "header-cell-style": { paddingLeft: "20px" },
        "cell-class-name": _vm.gradeInfo,
        "row-class-name": _vm.tabRowClassName,
        "row-merge-number": 2,
        "row-merge-hover-key": "drugId",
        height: "100%",
        border: "",
      },
      on: {
        "cell-mouse-enter": _vm.cellMouseEnter,
        "cell-mouse-leave": _vm.cellMouseLeave,
      },
    },
    [
      _c("el-table-column", {
        staticClass: "none-border",
        attrs: {
          align: "center",
          width: "150",
          prop: "drugId",
          "show-overflow-tooltip": "",
          fixed: "",
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [_c("span", [_vm._v("药品编码")])]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "span",
                  {
                    staticClass: "link",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.onShowSimpleOperation(scope)
                      },
                    },
                  },
                  [_vm._v(_vm._s(scope.row.drugId))]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          align: "center",
          width: "50",
          prop: "drugId",
          "show-overflow-tooltip": "",
          fixed: "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                scope.$index % 2 === 0
                  ? _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top-start",
                          title: "",
                          width: "150",
                          "popper-class": "type-icon-popper",
                          trigger: "hover",
                          content: "此行为机构数据",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-insti",
                          attrs: { slot: "reference" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.typeClick(scope)
                            },
                          },
                          slot: "reference",
                        }),
                      ]
                    )
                  : _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top-start",
                          title: "",
                          width: "150",
                          "popper-class": "type-icon-popper",
                          trigger: "hover",
                          content: "此行为平台数据",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-platform",
                          attrs: { slot: "reference" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.typeClick(scope)
                            },
                          },
                          slot: "reference",
                        }),
                      ]
                    ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          label: "药品通用名",
          prop: "genericNameCompare",
          width: "140",
          fixed: "",
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [
                _c("span", [_vm._v("药品通用名")]),
                _vm.searchObj.isFilter
                  ? _c(
                      "el-popover",
                      {
                        ref: "elPopover" + scope.column.property,
                        attrs: {
                          trigger: "click",
                          placement: "right",
                          "popper-class": "governance-poper",
                          "visible-arrow": true,
                        },
                        on: {
                          hide: function ($event) {
                            return _vm.onHidePopover(scope)
                          },
                          show: function ($event) {
                            return _vm.onShowPopover(scope)
                          },
                        },
                      },
                      [
                        _c("flat-filter", {
                          ref: "filter" + scope.$index,
                          attrs: {
                            "show-filter": _vm.searchObj.isFilter,
                            "filter-options": _vm.filterOptions,
                            position: _vm.filterCascaderPosition(scope),
                            column: scope,
                          },
                          on: { closeFilter: _vm.onCloseFilter },
                        }),
                        !_vm.filterChangeIcon[
                          scope.column.property + scope.$index
                        ]
                          ? _c("svg-icon", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.searchObj.isFilter,
                                  expression: "searchObj.isFilter",
                                },
                              ],
                              staticClass: "filter-icon fixed-column-icon",
                              staticStyle: {
                                position: "absolute",
                                right: "10px",
                                width: "20px",
                                height: "20px",
                              },
                              attrs: {
                                slot: "reference",
                                "icon-class": "shaixuan",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onFilterClick(scope)
                                },
                              },
                              slot: "reference",
                            })
                          : _vm.filterChangeIcon[
                              scope.column.property + scope.$index
                            ]
                          ? _c("svg-icon", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.searchObj.isFilter,
                                  expression: "searchObj.isFilter",
                                },
                              ],
                              staticClass:
                                "filter-icon fixed-column-selected-icon fixed-column-icon",
                              staticStyle: {
                                position: "absolute",
                                right: "10px",
                                width: "20px",
                                height: "20px",
                              },
                              attrs: {
                                slot: "reference",
                                "icon-class": "filter",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onFilterClick(scope)
                                },
                              },
                              slot: "reference",
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { staticClass: "fl" }, [
                  _vm._v(_vm._s(scope.row.genericNameCompare)),
                ]),
                _vm.isShowErrorDetails(scope)
                  ? _c("span", { staticClass: "error-details fr" }, [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.genericNameCompareerrorTypeStr) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "药品商品名", prop: "tradeNameCompare", width: "130" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [
                _c("span", [_vm._v("药品商品名")]),
                _c(
                  "el-popover",
                  {
                    ref: "elPopover" + scope.column.property,
                    attrs: {
                      trigger: "click",
                      placement: "right",
                      "popper-class": "governance-poper",
                      "visible-arrow": false,
                    },
                    on: {
                      hide: function ($event) {
                        return _vm.onHidePopover(scope)
                      },
                      show: function ($event) {
                        return _vm.onShowPopover(scope)
                      },
                    },
                  },
                  [
                    _c("flat-filter", {
                      ref: "filter" + scope.$index,
                      attrs: {
                        "show-filter": _vm.searchObj.isFilter,
                        "filter-options": _vm.filterOptions,
                        position: _vm.filterCascaderPosition(scope),
                        column: scope,
                      },
                      on: { closeFilter: _vm.onCloseFilter },
                    }),
                    !_vm.filterChangeIcon[scope.column.property + scope.$index]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: {
                            slot: "reference",
                            "icon-class": "shaixuan",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm.filterChangeIcon[
                          scope.column.property + scope.$index
                        ]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: { slot: "reference", "icon-class": "filter" },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { staticClass: "fl" }, [
                  _vm._v(_vm._s(scope.row.tradeNameCompare)),
                ]),
                _vm.isShowErrorDetails(scope)
                  ? _c("span", { staticClass: "error-details fr" }, [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.tradeNameCompareerrorTypeStr) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          label: "规格包装",
          "column-key": "filterVisible",
          prop: "specCompare",
          width: "140",
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [
                _c("span", [_vm._v("规格包装")]),
                _c(
                  "el-popover",
                  {
                    ref: "elPopover" + scope.column.property,
                    attrs: {
                      trigger: "click",
                      placement: "right",
                      "popper-class": "governance-poper",
                      "visible-arrow": false,
                    },
                    on: {
                      hide: function ($event) {
                        return _vm.onHidePopover(scope)
                      },
                      show: function ($event) {
                        return _vm.onShowPopover(scope)
                      },
                    },
                  },
                  [
                    _c("flat-filter", {
                      ref: "filter" + scope.$index,
                      attrs: {
                        "show-filter": _vm.searchObj.isFilter,
                        "filter-options": _vm.filterOptions,
                        position: _vm.filterCascaderPosition(scope),
                        column: scope,
                      },
                      on: { closeFilter: _vm.onCloseFilter },
                    }),
                    !_vm.filterChangeIcon[scope.column.property + scope.$index]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: {
                            slot: "reference",
                            "icon-class": "shaixuan",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm.filterChangeIcon[
                          scope.column.property + scope.$index
                        ]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: { slot: "reference", "icon-class": "filter" },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { staticClass: "fl" }, [
                  _vm._v(_vm._s(scope.row.specCompare)),
                ]),
                _vm.isShowErrorDetails(scope)
                  ? _c("span", { staticClass: "error-details fr" }, [
                      _vm._v(
                        " " + _vm._s(scope.row.specCompareerrorTypeStr) + " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "包装规格说明", prop: "specTextCompare", width: "140" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [
                _c("span", [_vm._v("包装规格说明")]),
                _c(
                  "el-popover",
                  {
                    ref: "elPopover" + scope.column.property,
                    attrs: {
                      trigger: "click",
                      placement: "right",
                      "popper-class": "governance-poper",
                      "visible-arrow": false,
                    },
                    on: {
                      hide: function ($event) {
                        return _vm.onHidePopover(scope)
                      },
                      show: function ($event) {
                        return _vm.onShowPopover(scope)
                      },
                    },
                  },
                  [
                    _c("flat-filter", {
                      ref: "filter" + scope.$index,
                      attrs: {
                        "show-filter": _vm.searchObj.isFilter,
                        "filter-options": _vm.filterOptions,
                        position: _vm.filterCascaderPosition(scope),
                        column: scope,
                      },
                      on: { closeFilter: _vm.onCloseFilter },
                    }),
                    !_vm.filterChangeIcon[scope.column.property + scope.$index]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: {
                            slot: "reference",
                            "icon-class": "shaixuan",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm.filterChangeIcon[
                          scope.column.property + scope.$index
                        ]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: { slot: "reference", "icon-class": "filter" },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { staticClass: "fl" }, [
                  _vm._v(_vm._s(scope.row.specTextCompare)),
                ]),
                _vm.isShowErrorDetails(scope)
                  ? _c("span", { staticClass: "error-details fr" }, [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.specTextCompareerrorTypeStr) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "剂型名称", width: "150", prop: "formNameCompare" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [
                _c("span", [_vm._v("剂型名称")]),
                _c(
                  "el-popover",
                  {
                    ref: "elPopover" + scope.column.property,
                    attrs: {
                      trigger: "click",
                      placement: "right",
                      "popper-class": "governance-poper",
                      "visible-arrow": false,
                    },
                    on: {
                      hide: function ($event) {
                        return _vm.onHidePopover(scope)
                      },
                      show: function ($event) {
                        return _vm.onShowPopover(scope)
                      },
                    },
                  },
                  [
                    _c("flat-filter", {
                      ref: "filter" + scope.$index,
                      attrs: {
                        "show-filter": _vm.searchObj.isFilter,
                        "filter-options": _vm.filterOptions,
                        position: _vm.filterCascaderPosition(scope),
                        column: scope,
                      },
                      on: { closeFilter: _vm.onCloseFilter },
                    }),
                    !_vm.filterChangeIcon[scope.column.property + scope.$index]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: {
                            slot: "reference",
                            "icon-class": "shaixuan",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm.filterChangeIcon[
                          scope.column.property + scope.$index
                        ]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: { slot: "reference", "icon-class": "filter" },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { staticClass: "fl" }, [
                  _vm._v(_vm._s(scope.row.formNameCompare)),
                ]),
                _vm.isShowErrorDetails(scope)
                  ? _c("span", { staticClass: "error-details fr" }, [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.formNameCompareerrorTypeStr) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "厂家名称", prop: "manufacturerCompare", width: "130" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [
                _c("span", [_vm._v("厂家名称")]),
                _c(
                  "el-popover",
                  {
                    ref: "elPopover" + scope.column.property,
                    attrs: {
                      trigger: "click",
                      placement: "right",
                      "popper-class": "governance-poper",
                      "visible-arrow": false,
                    },
                    on: {
                      hide: function ($event) {
                        return _vm.onHidePopover(scope)
                      },
                      show: function ($event) {
                        return _vm.onShowPopover(scope)
                      },
                    },
                  },
                  [
                    _c("flat-filter", {
                      ref: "filter" + scope.$index,
                      attrs: {
                        "show-filter": _vm.searchObj.isFilter,
                        "filter-options": _vm.filterOptions,
                        position: _vm.filterCascaderPosition(scope),
                        column: scope,
                      },
                      on: { closeFilter: _vm.onCloseFilter },
                    }),
                    !_vm.filterChangeIcon[scope.column.property + scope.$index]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: {
                            slot: "reference",
                            "icon-class": "shaixuan",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm.filterChangeIcon[
                          scope.column.property + scope.$index
                        ]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: { slot: "reference", "icon-class": "filter" },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { staticClass: "fl" }, [
                  _vm._v(_vm._s(scope.row.manufacturerCompare)),
                ]),
                _vm.isShowErrorDetails(scope)
                  ? _c("span", { staticClass: "error-details fr" }, [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.manufacturerCompareerrorTypeStr) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "批准文号", prop: "approvalNoCompare", width: "140" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [
                _c("span", [_vm._v("批准文号")]),
                _c(
                  "el-popover",
                  {
                    ref: "elPopover" + scope.column.property,
                    attrs: {
                      trigger: "click",
                      placement: "left",
                      "popper-class": "governance-poper",
                      "visible-arrow": false,
                    },
                    on: {
                      hide: function ($event) {
                        return _vm.onHidePopover(scope)
                      },
                      show: function ($event) {
                        return _vm.onShowPopover(scope)
                      },
                    },
                  },
                  [
                    _c("flat-filter", {
                      ref: "filter" + scope.$index,
                      attrs: {
                        "show-filter": _vm.searchObj.isFilter,
                        "filter-options": _vm.filterOptions,
                        position: _vm.filterCascaderPosition(scope),
                        column: scope,
                      },
                      on: { closeFilter: _vm.onCloseFilter },
                    }),
                    !_vm.filterChangeIcon[scope.column.property + scope.$index]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: {
                            slot: "reference",
                            "icon-class": "shaixuan",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm.filterChangeIcon[
                          scope.column.property + scope.$index
                        ]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: { slot: "reference", "icon-class": "filter" },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { staticClass: "fl" }, [
                  _vm._v(_vm._s(scope.row.approvalNoCompare)),
                ]),
                _vm.isShowErrorDetails(scope)
                  ? _c("span", { staticClass: "error-details fr" }, [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.approvalNoCompareerrorTypeStr) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "国基标志", prop: "nedFlagCompare", width: "120" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [
                _c("span", [_vm._v("国基标志")]),
                _c(
                  "el-popover",
                  {
                    ref: "elPopover" + scope.column.property,
                    attrs: {
                      trigger: "click",
                      placement: "right",
                      "popper-class": "governance-poper",
                      "visible-arrow": false,
                      position: "left",
                    },
                    on: {
                      hide: function ($event) {
                        return _vm.onHidePopover(scope)
                      },
                      show: function ($event) {
                        return _vm.onShowPopover(scope)
                      },
                    },
                  },
                  [
                    _c("flat-filter", {
                      ref: "filter" + scope.$index,
                      attrs: {
                        "show-filter": _vm.searchObj.isFilter,
                        "filter-options": _vm.filterOptions,
                        position: _vm.filterCascaderPosition(scope),
                        column: scope,
                      },
                      on: { closeFilter: _vm.onCloseFilter },
                    }),
                    !_vm.filterChangeIcon[scope.column.property + scope.$index]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: {
                            slot: "reference",
                            "icon-class": "shaixuan",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm.filterChangeIcon[
                          scope.column.property + scope.$index
                        ]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: { slot: "reference", "icon-class": "filter" },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { staticClass: "fl" }, [
                  _vm._v(_vm._s(scope.row.nedFlagCompare)),
                ]),
                _vm.isShowErrorDetails(scope)
                  ? _c("span", { staticClass: "error-details fr" }, [
                      _vm._v(
                        " " + _vm._s(scope.row.nedFlagCompareerrorTypeStr) + " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm.showShangHai
        ? _c("el-table-column", {
            attrs: {
              label: "上基标志",
              prop: "shNedFlagCompare",
              width: "120",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v("上基标志")]),
                      _c(
                        "el-popover",
                        {
                          ref: "elPopover" + scope.column.property,
                          attrs: {
                            trigger: "click",
                            placement: "right",
                            "popper-class": "governance-poper",
                            "visible-arrow": false,
                          },
                          on: {
                            hide: function ($event) {
                              return _vm.onHidePopover(scope)
                            },
                            show: function ($event) {
                              return _vm.onShowPopover(scope)
                            },
                          },
                        },
                        [
                          _c("flat-filter", {
                            ref: "filter" + scope.$index,
                            attrs: {
                              "show-filter": _vm.searchObj.isFilter,
                              "filter-options": _vm.filterOptions,
                              position: _vm.filterCascaderPosition(scope),
                              column: scope,
                            },
                            on: { closeFilter: _vm.onCloseFilter },
                          }),
                          !_vm.filterChangeIcon[
                            scope.column.property + scope.$index
                          ]
                            ? _c("svg-icon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.searchObj.isFilter,
                                    expression: "searchObj.isFilter",
                                  },
                                ],
                                staticClass: "filter-icon",
                                staticStyle: {
                                  position: "absolute",
                                  right: "10px",
                                  width: "20px",
                                  height: "20px",
                                },
                                attrs: {
                                  slot: "reference",
                                  "icon-class": "shaixuan",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onFilterClick(scope)
                                  },
                                },
                                slot: "reference",
                              })
                            : _vm.filterChangeIcon[
                                scope.column.property + scope.$index
                              ]
                            ? _c("svg-icon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.searchObj.isFilter,
                                    expression: "searchObj.isFilter",
                                  },
                                ],
                                staticClass: "filter-icon",
                                staticStyle: {
                                  position: "absolute",
                                  right: "10px",
                                  width: "20px",
                                  height: "20px",
                                },
                                attrs: {
                                  slot: "reference",
                                  "icon-class": "filter",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onFilterClick(scope)
                                  },
                                },
                                slot: "reference",
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", { staticClass: "fl" }, [
                        _vm._v(_vm._s(scope.row.shNedFlagCompare)),
                      ]),
                      _vm.isShowErrorDetails(scope)
                        ? _c("span", { staticClass: "error-details fr" }, [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.shNedFlagCompareerrorTypeStr) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              1483920729
            ),
          })
        : _vm._e(),
      _c("el-table-column", {
        attrs: {
          label: "高危药品分级名称",
          prop: "highRiskLvCompare",
          width: "180",
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [
                _c("span", [_vm._v("高危药品分级名称")]),
                _c(
                  "el-popover",
                  {
                    ref: "elPopover" + scope.column.property,
                    attrs: {
                      trigger: "click",
                      placement: "right",
                      "popper-class": "governance-poper",
                      "visible-arrow": false,
                      position: "left",
                    },
                    on: {
                      hide: function ($event) {
                        return _vm.onHidePopover(scope)
                      },
                      show: function ($event) {
                        return _vm.onShowPopover(scope)
                      },
                    },
                  },
                  [
                    _c("flat-filter", {
                      ref: "filter" + scope.$index,
                      attrs: {
                        "show-filter": _vm.searchObj.isFilter,
                        "filter-options": _vm.filterOptions,
                        position: _vm.filterCascaderPosition(scope),
                        column: scope,
                      },
                      on: { closeFilter: _vm.onCloseFilter },
                    }),
                    !_vm.filterChangeIcon[scope.column.property + scope.$index]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: {
                            slot: "reference",
                            "icon-class": "shaixuan",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm.filterChangeIcon[
                          scope.column.property + scope.$index
                        ]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: { slot: "reference", "icon-class": "filter" },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { staticClass: "fl" }, [
                  _vm._v(_vm._s(scope.row.highRiskLvCompare)),
                ]),
                _vm.isShowErrorDetails(scope)
                  ? _c("span", { staticClass: "error-details fr" }, [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.highRiskLvCompareerrorTypeStr) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          label: "抗菌药分级名称",
          prop: "antibioticLvCompare",
          width: "160",
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [
                _c("span", [_vm._v("抗菌药分级名称")]),
                _c(
                  "el-popover",
                  {
                    ref: "elPopover" + scope.column.property,
                    attrs: {
                      trigger: "click",
                      placement: "right",
                      "popper-class": "governance-poper",
                      "visible-arrow": false,
                    },
                    on: {
                      hide: function ($event) {
                        return _vm.onHidePopover(scope)
                      },
                      show: function ($event) {
                        return _vm.onShowPopover(scope)
                      },
                    },
                  },
                  [
                    _c("flat-filter", {
                      ref: "filter" + scope.$index,
                      attrs: {
                        "show-filter": _vm.searchObj.isFilter,
                        "filter-options": _vm.filterOptions,
                        position: _vm.filterCascaderPosition(scope),
                        column: scope,
                      },
                      on: { closeFilter: _vm.onCloseFilter },
                    }),
                    !_vm.filterChangeIcon[scope.column.property + scope.$index]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: {
                            slot: "reference",
                            "icon-class": "shaixuan",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm.filterChangeIcon[
                          scope.column.property + scope.$index
                        ]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: { slot: "reference", "icon-class": "filter" },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { staticClass: "fl" }, [
                  _vm._v(_vm._s(scope.row.antibioticLvCompare)),
                ]),
                _vm.isShowErrorDetails(scope)
                  ? _c("span", { staticClass: "error-details fr" }, [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.antibioticLvCompareerrorTypeStr) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          label: "精麻毒放",
          prop: "toxicNarcoticTypeCompare",
          width: "130",
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [
                _c("span", [_vm._v("精麻毒放")]),
                _c(
                  "el-popover",
                  {
                    ref: "elPopover" + scope.column.property,
                    attrs: {
                      trigger: "click",
                      placement: "right",
                      "popper-class": "governance-poper",
                      "visible-arrow": false,
                    },
                    on: {
                      hide: function ($event) {
                        return _vm.onHidePopover(scope)
                      },
                      show: function ($event) {
                        return _vm.onShowPopover(scope)
                      },
                    },
                  },
                  [
                    _c("flat-filter", {
                      ref: "filter" + scope.$index,
                      attrs: {
                        "show-filter": _vm.searchObj.isFilter,
                        "filter-options": _vm.filterOptions,
                        position: _vm.filterCascaderPosition(scope),
                        column: scope,
                      },
                      on: { closeFilter: _vm.onCloseFilter },
                    }),
                    !_vm.filterChangeIcon[scope.column.property + scope.$index]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: {
                            slot: "reference",
                            "icon-class": "shaixuan",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm.filterChangeIcon[
                          scope.column.property + scope.$index
                        ]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: { slot: "reference", "icon-class": "filter" },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { staticClass: "fl" }, [
                  _vm._v(_vm._s(scope.row.toxicNarcoticTypeCompare)),
                ]),
                _vm.isShowErrorDetails(scope)
                  ? _c("span", { staticClass: "error-details fr" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.row.toxicNarcoticTypeCompareerrorTypeStr
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "药品分类", prop: "phaClassCompare", width: "120" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [
                _c("span", [_vm._v("药品分类")]),
                _c(
                  "el-popover",
                  {
                    ref: "elPopover" + scope.column.property,
                    attrs: {
                      trigger: "click",
                      placement: "right",
                      "popper-class": "governance-poper",
                      "visible-arrow": false,
                    },
                    on: {
                      hide: function ($event) {
                        return _vm.onHidePopover(scope)
                      },
                      show: function ($event) {
                        return _vm.onShowPopover(scope)
                      },
                    },
                  },
                  [
                    _c("flat-filter", {
                      ref: "filter" + scope.$index,
                      attrs: {
                        "show-filter": _vm.searchObj.isFilter,
                        "filter-options": _vm.filterOptions,
                        position: _vm.filterCascaderPosition(scope),
                        column: scope,
                      },
                      on: { closeFilter: _vm.onCloseFilter },
                    }),
                    !_vm.filterChangeIcon[scope.column.property + scope.$index]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: {
                            slot: "reference",
                            "icon-class": "shaixuan",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm.filterChangeIcon[
                          scope.column.property + scope.$index
                        ]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: { slot: "reference", "icon-class": "filter" },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { staticClass: "fl" }, [
                  _vm._v(_vm._s(scope.row.phaClassCompare)),
                ]),
                _vm.isShowErrorDetails(scope)
                  ? _c("span", { staticClass: "error-details fr" }, [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.phaClassCompareerrorTypeStr) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "DDD", prop: "dddCompare" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [
                _c("span", [_vm._v("DDD")]),
                _c(
                  "el-popover",
                  {
                    ref: "elPopover" + scope.column.property,
                    attrs: {
                      trigger: "click",
                      placement: "right",
                      "popper-class": "governance-poper",
                      "visible-arrow": false,
                    },
                    on: {
                      hide: function ($event) {
                        return _vm.onHidePopover(scope)
                      },
                      show: function ($event) {
                        return _vm.onShowPopover(scope)
                      },
                    },
                  },
                  [
                    _c("flat-filter", {
                      ref: "filter" + scope.$index,
                      attrs: {
                        "show-filter": _vm.searchObj.isFilter,
                        "filter-options": _vm.filterOptions,
                        position: _vm.filterCascaderPosition(scope),
                        column: scope,
                      },
                      on: { closeFilter: _vm.onCloseFilter },
                    }),
                    !_vm.filterChangeIcon[scope.column.property + scope.$index]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: {
                            slot: "reference",
                            "icon-class": "shaixuan",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm.filterChangeIcon[
                          scope.column.property + scope.$index
                        ]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: { slot: "reference", "icon-class": "filter" },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { staticClass: "fl" }, [
                  _vm._v(_vm._s(scope.row.dddCompare)),
                ]),
                _vm.isShowErrorDetails(scope)
                  ? _c("span", { staticClass: "error-details fr" }, [
                      _vm._v(
                        " " + _vm._s(scope.row.dddCompareerrorTypeStr) + " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          label: "中西草生物制品类型名称",
          prop: "classCompare",
          width: "210",
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [
                _c("span", [_vm._v("中西草生物制品类型名称")]),
                _c(
                  "el-popover",
                  {
                    ref: "elPopover" + scope.column.property,
                    attrs: {
                      trigger: "click",
                      placement: "right",
                      "popper-class": "governance-poper",
                      "visible-arrow": false,
                    },
                    on: {
                      hide: function ($event) {
                        return _vm.onHidePopover(scope)
                      },
                      show: function ($event) {
                        return _vm.onShowPopover(scope)
                      },
                    },
                  },
                  [
                    _c("flat-filter", {
                      ref: "filter" + scope.$index,
                      attrs: {
                        "show-filter": _vm.searchObj.isFilter,
                        "filter-options": _vm.filterOptions,
                        position: _vm.filterCascaderPosition(scope),
                        column: scope,
                      },
                      on: { closeFilter: _vm.onCloseFilter },
                    }),
                    !_vm.filterChangeIcon[scope.column.property + scope.$index]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: {
                            slot: "reference",
                            "icon-class": "shaixuan",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm.filterChangeIcon[
                          scope.column.property + scope.$index
                        ]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: { slot: "reference", "icon-class": "filter" },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { staticClass: "fl" }, [
                  _vm._v(_vm._s(scope.row.classCompare)),
                ]),
                _vm.isShowErrorDetails(scope)
                  ? _c("span", { staticClass: "error-details fr" }, [
                      _vm._v(
                        " " + _vm._s(scope.row.classCompareerrorTypeStr) + " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm.showShangHai
        ? _c("el-table-column", {
            attrs: {
              label: "上海阳光采购平台药品统编码",
              prop: "ustdCodeCompare",
              width: "250",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v("上海阳光采购平台药品统编码")]),
                      _c(
                        "el-popover",
                        {
                          ref: "elPopover" + scope.column.property,
                          attrs: {
                            trigger: "click",
                            placement: "right",
                            "popper-class": "governance-poper",
                            "visible-arrow": false,
                          },
                          on: {
                            hide: function ($event) {
                              return _vm.onHidePopover(scope)
                            },
                            show: function ($event) {
                              return _vm.onShowPopover(scope)
                            },
                          },
                        },
                        [
                          _c("flat-filter", {
                            ref: "filter" + scope.$index,
                            attrs: {
                              "show-filter": _vm.searchObj.isFilter,
                              "filter-options": _vm.filterOptions,
                              position: _vm.filterCascaderPosition(scope),
                              column: scope,
                            },
                            on: { closeFilter: _vm.onCloseFilter },
                          }),
                          !_vm.filterChangeIcon[
                            scope.column.property + scope.$index
                          ]
                            ? _c("svg-icon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.searchObj.isFilter,
                                    expression: "searchObj.isFilter",
                                  },
                                ],
                                staticClass: "filter-icon",
                                staticStyle: {
                                  position: "absolute",
                                  right: "10px",
                                  width: "20px",
                                  height: "20px",
                                },
                                attrs: {
                                  slot: "reference",
                                  "icon-class": "shaixuan",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onFilterClick(scope)
                                  },
                                },
                                slot: "reference",
                              })
                            : _vm.filterChangeIcon[
                                scope.column.property + scope.$index
                              ]
                            ? _c("svg-icon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.searchObj.isFilter,
                                    expression: "searchObj.isFilter",
                                  },
                                ],
                                staticClass: "filter-icon",
                                staticStyle: {
                                  position: "absolute",
                                  right: "10px",
                                  width: "20px",
                                  height: "20px",
                                },
                                attrs: {
                                  slot: "reference",
                                  "icon-class": "filter",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onFilterClick(scope)
                                  },
                                },
                                slot: "reference",
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", { staticClass: "fl" }, [
                        _vm._v(_vm._s(scope.row.ustdCodeCompare)),
                      ]),
                      _vm.isShowErrorDetails(scope)
                        ? _c("span", { staticClass: "error-details fr" }, [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.ustdCodeCompareerrorTypeStr) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              2733853329
            ),
          })
        : _vm._e(),
      _c("el-table-column", {
        attrs: { label: "集采标志", prop: "ncpFlagCompare", width: "170" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [
                _c("span", [_vm._v("集采标志")]),
                _c(
                  "el-popover",
                  {
                    ref: "elPopover" + scope.column.property,
                    attrs: {
                      trigger: "click",
                      placement: "right",
                      "popper-class": "governance-poper",
                      "visible-arrow": false,
                    },
                    on: {
                      hide: function ($event) {
                        return _vm.onHidePopover(scope)
                      },
                      show: function ($event) {
                        return _vm.onShowPopover(scope)
                      },
                    },
                  },
                  [
                    _c("flat-filter", {
                      ref: "filter" + scope.$index,
                      attrs: {
                        "show-filter": _vm.searchObj.isFilter,
                        "filter-options": _vm.filterOptions,
                        position: _vm.filterCascaderPosition(scope),
                        column: scope,
                      },
                      on: { closeFilter: _vm.onCloseFilter },
                    }),
                    !_vm.filterChangeIcon[scope.column.property + scope.$index]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: {
                            slot: "reference",
                            "icon-class": "shaixuan",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm.filterChangeIcon[
                          scope.column.property + scope.$index
                        ]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: { slot: "reference", "icon-class": "filter" },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { staticClass: "fl" }, [
                  _vm._v(_vm._s(scope.row.ncpFlagCompare)),
                ]),
                _vm.isShowErrorDetails(scope)
                  ? _c("span", { staticClass: "error-details fr" }, [
                      _vm._v(
                        " " + _vm._s(scope.row.ncpFlagCompareerrorTypeStr) + " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "国家贯标码", prop: "nhsaCodeCompare", width: "140" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (scope) {
              return [
                _c("span", [_vm._v("国家贯标码")]),
                _c(
                  "el-popover",
                  {
                    ref: "elPopover" + scope.column.property,
                    attrs: {
                      trigger: "click",
                      placement: "top-start",
                      "popper-class": "governance-poper",
                      "visible-arrow": false,
                      "popper-options": {
                        boundariesElement: "viewport",
                        removeOnDestroy: true,
                      },
                    },
                    on: {
                      hide: function ($event) {
                        return _vm.onHidePopover(scope)
                      },
                      show: function ($event) {
                        return _vm.onShowPopover(scope)
                      },
                    },
                  },
                  [
                    _c("flat-filter", {
                      ref: "filter" + scope.$index,
                      attrs: {
                        "show-filter": _vm.searchObj.isFilter,
                        "filter-options": _vm.filterOptions,
                        position: _vm.filterCascaderPosition(scope),
                        column: scope,
                      },
                      on: { closeFilter: _vm.onCloseFilter },
                    }),
                    !_vm.filterChangeIcon[scope.column.property + scope.$index]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: {
                            slot: "reference",
                            "icon-class": "shaixuan",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm.filterChangeIcon[
                          scope.column.property + scope.$index
                        ]
                      ? _c("svg-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchObj.isFilter,
                              expression: "searchObj.isFilter",
                            },
                          ],
                          staticClass: "filter-icon",
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                          },
                          attrs: { slot: "reference", "icon-class": "filter" },
                          on: {
                            click: function ($event) {
                              return _vm.onFilterClick(scope)
                            },
                          },
                          slot: "reference",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { staticClass: "fl" }, [
                  _vm._v(_vm._s(scope.row.nhsaCodeCompare)),
                ]),
                _vm.isShowErrorDetails(scope)
                  ? _c("span", { staticClass: "error-details fr" }, [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.nhsaCodeCompareerrorTypeStr) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm.dialogVisible
        ? _c("simple-check-report-vue", {
            attrs: {
              "dialog-visible": _vm.dialogVisible,
              "drug-id": _vm.currentRowBySimple.drugId,
              "origin-table-data": _vm.tableDataInitial,
              "origin-data": _vm.currentRowBySimple,
              "grade-options": _vm.gradeOptions,
              "show-operation": false,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.dialogVisible = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }