<!--平台端查看治理报告-->
<template>
  <view-report :data-from="dataFrom" :apply-id-from-other="applyId"/>
</template>
<script>
import ViewReport from '../../institutionGovernanceReport/index'
export default {
  components: {
    ViewReport
  },
  data() {
    return {
      dataFrom: 'other',
      applyId: ''
    }
  },
  created() {
    this.applyId = Number(this.$route.query.applyId)
  }
}
</script>
<style lang="scss" scoped></style>
