<!--数据来源弹窗-->
<template>
  <el-dialog
		v-if="dialogVisible"
    title="查看数据来源"
    :visible="dialogVisible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="soure-data-dialog vertical-middle-dialog"
    width="800px"
		:append-to-body="true"
    center
    :before-close="handleClose">
    <el-table
      ref="no-map-table"
      :data="tableData"
      :header-cell-style="{textAlign: 'center'}"
      style="width: 100%;"
      height="100%"
      stripe
      class="no-match-report-table"
      border>
      <el-table-column
        label="字段名"
        prop="fieldName"
        width="400">
      </el-table-column>
      <el-table-column
        label="主要参考来源"
        prop="mainRefSourece">
      </el-table-column>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downloadHandle">下载</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span> -->
    </el-table>
  </el-dialog>
</template>
<script>
  export default {
    props: {
      dialogVisible: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        tableData: [
          {
            id: 1,
            fieldName: '药品名、商品名、批准文号、规格、剂型、厂商、中西草分类',
            mainRefSourece: '国药监局网站、药品说明书、中国药典、临床用药须知'
          },
          {
            id: 2,
            fieldName: '药品包装',
            mainRefSourece: '药品说明书、国家医保药品与分类、上海医保目录'
          },
          {
            id: 3,
            fieldName: '贯标码',
            mainRefSourece: '国家医保药品与分类'
          },
          {
            id: 4,
            fieldName: '上海统编码',
            mainRefSourece: '上海医保目录'
          },
          {
            id: 5,
            fieldName: '基药标志',
            mainRefSourece: '国家基本药物目录'
          },
          {
            id: 6,
            fieldName: '高危药品分级',
            mainRefSourece: '中国药学会医院药学专业委员会高警示药品推荐目录'
          },
          {
            id: 7,
            fieldName: '抗菌药物分级',
            mainRefSourece: '各地方抗菌药物分级管理文件。如：上海市医疗机构抗菌药物临床应用分级管理目录（2021年版）、河南省抗菌药物临床应用分级管理目录（2021年版）等'
          },
          {
            id: 8,
            fieldName: '精麻毒放分类',
            mainRefSourece: '国家发布的政策文件'
          },
          {
            id: 9,
            fieldName: '药品分类',
            mainRefSourece: '西药使用WHO-ATC分类；中成药-按科室功效分类'
          },
          {
            id: 10,
            fieldName: 'DDD',
            mainRefSourece: 'WHO 发布DDD值、卫生部抗菌药物临床应用监测网药品字典及DDD值'
          },
          {
            id: 11,
            fieldName: '集采标志（带量采购）',
            mainRefSourece: '国家组织药品联合采购办公室采购与中选公告'
          }
        ]
      }
    },
    methods: {
      handleClose() {
        this.$emit('update:dialogVisible', false)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .soure-data-dialog {
    ::v-deep.el-dialog {
      height: 520px !important;
      padding-bottom: 15px !important;
    }
  }
</style>