var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _vm.applyId && !_vm.enlarge && _vm.hasReport
        ? _c(
            "el-container",
            {
              key: _vm.applyId,
              staticClass: "layout-container insti-report-container",
            },
            [
              _c(
                "el-aside",
                { staticClass: "insti-report-aside box-shadow-radius" },
                [
                  _c(
                    "section",
                    { staticClass: "health-index-section clearfix" },
                    [
                      _c(
                        "div",
                        { staticClass: "title-header-container" },
                        [
                          _c("div", [
                            _c("span", { staticClass: "title-header" }),
                            _c("h4", { staticClass: "title-text" }, [
                              _vm._v(" 健康指数 "),
                            ]),
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.onShowHistoryVersion },
                            },
                            [_vm._v("查看历史版本")]
                          ),
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v("当前版本："),
                        _c("span", [
                          _vm._v(_vm._s(_vm.scoreDataObj.simpleVersion)),
                        ]),
                      ]),
                      _c("div", { staticClass: "ring-chart-container" }, [
                        _c(
                          "section",
                          {
                            staticStyle: {
                              "margin-bottom": "20px",
                              width: "100%",
                            },
                            attrs: { id: "ring-chart-section" },
                          },
                          [
                            _c("div", {
                              style: _vm.ringChartStyle,
                              attrs: { id: "ring-chart" },
                            }),
                            _c(
                              "p",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c("span", [_vm._v("与上次相比：")]),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#2B60F8",
                                      "font-size": "16px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("indexCompareAbsoluteValue")(
                                          _vm.scoreDataObj
                                            .indexCompareAbsoluteValue,
                                          _vm.scoreDataObj
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm.showTrendIcon
                                  ? _c("svg-icon", {
                                      staticClass: "babu-svg",
                                      attrs: { "icon-class": _vm.trendIcon },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "section",
                    {
                      staticClass: "radar-section",
                      attrs: { id: "radar-chart-section" },
                    },
                    [
                      _c("div", { staticClass: "title-header-container" }, [
                        _c("div", [
                          _c("span", { staticClass: "title-header" }),
                          _c("h4", { staticClass: "title-text" }, [
                            _vm._v(" 雷达分析 "),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "radar-wrap" }, [
                        _c("div", {
                          staticClass: "radar-chart",
                          style: _vm.radarChartStyle,
                          attrs: { id: "radar-chart" },
                        }),
                      ]),
                    ]
                  ),
                  _c("history-list", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isShowHistoryList,
                        expression: "isShowHistoryList",
                      },
                    ],
                    ref: "historyRef",
                    attrs: {
                      "apply-id": _vm.applyId,
                      "data-from": _vm.dataFrom,
                    },
                    on: { changeVersion: _vm.onChangeVersion },
                  }),
                ],
                1
              ),
              _c(
                "el-container",
                {
                  staticClass:
                    "layout-container insti-report-content-container",
                },
                [
                  _c(
                    "el-header",
                    {
                      staticClass: "chart-container box-shadow-radius clearfix",
                    },
                    [
                      _c(
                        "section",
                        {
                          staticClass:
                            "match-item-section clearfix box-shadow-radius",
                          staticStyle: { position: "relative" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title-header-container",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c("div", [
                                _c("span", { staticClass: "title-header" }),
                                _c("h4", { staticClass: "title-text" }, [
                                  _vm._v(" 匹配情况 "),
                                ]),
                              ]),
                              _vm.dataFrom === "self"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-right": "8px" },
                                      attrs: { type: "primary", size: "small" },
                                      on: { click: _vm.onApplyReport },
                                    },
                                    [_vm._v("申请报告")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("div", {
                            staticClass: "fl",
                            staticStyle: { height: "186px", width: "70%" },
                            attrs: { id: "pie-chart" },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "pie-legend-wrap fr",
                              staticStyle: { width: "30%" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "match-legend pie-legend",
                                  class: { active: _vm.isSelectedMatch },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onLegendClick("match")
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "match-circle legend-circle",
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "match-text legend-text" },
                                    [_vm._v("匹配项")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "no-match-legend pie-legend",
                                  class: { active: !_vm.isSelectedMatch },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onLegendClick("no-match")
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "no-match-circle legend-circle",
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "no-match-text legend-text",
                                    },
                                    [_vm._v("未匹配项")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "section",
                        {
                          staticClass:
                            "no-match-item-section box-shadow-radius",
                        },
                        [
                          _c(
                            "transition",
                            {
                              attrs: { name: "component-fade", mode: "out-in" },
                            },
                            [
                              _vm.applyId
                                ? _c(_vm.which_to_show, {
                                    tag: "component",
                                    attrs: { applyId: _vm.applyId },
                                    on: {
                                      matchSitutionsObj:
                                        _vm.getmatchSitutionsObj,
                                      noMatchObj: _vm.getNoMatchObj,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-main",
                    {
                      staticClass: "box-shadow-radius",
                      staticStyle: { padding: "0" },
                    },
                    [
                      _c(
                        "el-container",
                        { staticClass: "layout-container table-container" },
                        [
                          _c(
                            "el-header",
                            { staticClass: "report-table-header" },
                            [
                              _c(
                                "div",
                                { staticClass: "title-header-container" },
                                [
                                  _c("span", { staticClass: "title-header" }),
                                  _c(
                                    "h4",
                                    { staticClass: "title-text" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.searchTitle) + " "
                                      ),
                                      _c("svg-icon", {
                                        staticStyle: {
                                          width: "18px",
                                          height: "18px",
                                          cursor: "pointer",
                                          position: "absolute",
                                          top: "50%",
                                          transform: "translateY(-50%)",
                                        },
                                        attrs: {
                                          "icon-class": "wenhao",
                                          "fill-color": "#0073E9",
                                        },
                                        on: { click: _vm.onShowSource },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "el-form",
                                {
                                  staticClass: "search-form",
                                  attrs: {
                                    model: _vm.tableSearchObj,
                                    inline: true,
                                  },
                                },
                                [
                                  !_vm.isSelectedMatch
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { label: "未匹配原因：" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                multiple: "",
                                                "collapse-tags": "",
                                                placeholder: "请选择",
                                              },
                                              model: {
                                                value:
                                                  _vm.tableSearchObj
                                                    .errorCodeList,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.tableSearchObj,
                                                    "errorCodeList",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "tableSearchObj.errorCodeList",
                                              },
                                            },
                                            _vm._l(
                                              _vm.errorOptions,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.code,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.code,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "输入编号/药品通用名",
                                        },
                                        model: {
                                          value: _vm.tableSearchObj.searchValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tableSearchObj,
                                              "searchValue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tableSearchObj.searchValue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.isSelectedMatch
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { label: "只看问题字段" } },
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#2c5ff9",
                                              "inactive-color": "#b5ccf5",
                                            },
                                            on: {
                                              change: _vm.errorFilterChange,
                                            },
                                            model: {
                                              value:
                                                _vm.tableSearchObj
                                                  .errorProblemnFlag,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.tableSearchObj,
                                                  "errorProblemnFlag",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tableSearchObj.errorProblemnFlag",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isSelectedMatch
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { label: "筛选" } },
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#2c5ff9",
                                              "inactive-color": "#b5ccf5",
                                            },
                                            on: { change: _vm.isFilterChange },
                                            model: {
                                              value:
                                                _vm.tableSearchObj.isFilter,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.tableSearchObj,
                                                  "isFilter",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tableSearchObj.isFilter",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isSelectedMatch
                                    ? _c(
                                        "el-form-item",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                              },
                                              on: { click: _vm.onFilterReset },
                                            },
                                            [_vm._v("筛选重置")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                            icon: "el-icon-search",
                                          },
                                          on: {
                                            click: _vm.onSearchReportTable,
                                          },
                                        },
                                        [_vm._v("查询")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            icon: "el-icon-zoom-in",
                                            type: "primary",
                                          },
                                          on: { click: _vm.onEnlarge },
                                        },
                                        [_vm._v("放大")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-main",
                            { staticStyle: { padding: "5px 15px 0 15px" } },
                            [
                              !_vm.isSelectedMatch
                                ? _c("no-match-report", {
                                    ref: "noMatchTable",
                                    attrs: {
                                      "search-obj": _vm.tableSearchObj,
                                      "apply-id": _vm.applyId,
                                    },
                                    on: { noMatchTable: _vm.getNoMatchTable },
                                  })
                                : _vm._e(),
                              _vm.isSelectedMatch && _vm.applyId
                                ? _c("match-report", {
                                    ref: "matchTable",
                                    attrs: {
                                      "search-obj": _vm.tableSearchObj,
                                      "apply-id": _vm.applyId,
                                    },
                                    on: { matchTable: _vm.getMatchTable },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "el-footer",
                            {
                              staticClass: "clearfix",
                              staticStyle: { height: "42px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "fl",
                                  staticStyle: { "line-height": "42px" },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-warning",
                                    staticStyle: {
                                      color: "#94AFF7",
                                      "vertical-align": "middle",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "margin-left": "3px",
                                        "font-size": "12px",
                                        color: "#999",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "系统所展示药品信息，仅供参考，不作为使用依据"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("pagination", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.total > 0,
                                    expression: "total > 0",
                                  },
                                ],
                                staticClass: "fr",
                                attrs: {
                                  total: _vm.total,
                                  page: _vm.tableSearchObj.pageNum,
                                  limit: _vm.tableSearchObj.pageSize,
                                },
                                on: {
                                  "update:page": function ($event) {
                                    return _vm.$set(
                                      _vm.tableSearchObj,
                                      "pageNum",
                                      $event
                                    )
                                  },
                                  "update:limit": function ($event) {
                                    return _vm.$set(
                                      _vm.tableSearchObj,
                                      "pageSize",
                                      $event
                                    )
                                  },
                                  pagination: _vm.getTableHandle,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("source-dialog-vue", {
                            attrs: { "dialog-visible": _vm.dialogVisible },
                            on: {
                              "update:dialogVisible": function ($event) {
                                _vm.dialogVisible = $event
                              },
                              "update:dialog-visible": function ($event) {
                                _vm.dialogVisible = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.enlarge
        ? _c(
            "el-container",
            {
              staticClass: "layout-container table-container enlarge-container",
            },
            [
              _c("enlarge-component-vue", {
                attrs: {
                  enlarge: _vm.enlarge,
                  "is-selected-match": _vm.isSelectedMatch,
                  "apply-id": _vm.applyId,
                  "error-options": _vm.errorOptions,
                  "search-title": _vm.searchTitle,
                },
                on: {
                  "update:enlarge": function ($event) {
                    _vm.enlarge = $event
                  },
                  errorFilterChange: _vm.errorFilterChange,
                  onSearchReportTable: _vm.onSearchReportTable,
                },
              }),
            ],
            1
          )
        : !_vm.applyId && !_vm.enlarge && !_vm.hasReport
        ? _c(
            "el-container",
            {
              staticClass:
                "layout-container no-data-container empty-layout-container",
            },
            [
              _c("el-main", { staticClass: "no-data-main" }, [
                _c(
                  "div",
                  { staticClass: "none-data-wrap" },
                  [
                    _c("h3", { staticClass: "no-data-title" }, [
                      _vm._v("线上治理更省心，机构治理报告 "),
                    ]),
                    _c("span", { staticClass: "no-data-subtext" }, [
                      _vm._v("致力于底层药物数据的治理与管理"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.onApplyReport },
                      },
                      [_vm._v("申请报告")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }