<!---机构和平台---查看报告的时候显示的已匹配表格-->
<template>
  <win-table
    :data="tableData"
    :span-method="getSummaries"
    :header-cell-class-name="headerGradeInfo"
    :header-cell-style="{paddingLeft:'20px'}"
    :cell-class-name="gradeInfo"
    :row-class-name="tabRowClassName"
    :row-merge-number="2"
    row-merge-hover-key="drugId"
    style="width: 100%;"
    height="100%"
    ref="matchReportTableRef"
    class="match-report-table"
    border
    @cell-mouse-enter="cellMouseEnter"
    @cell-mouse-leave="cellMouseLeave">
    <el-table-column
      align="center"
      width="150"
      prop="drugId"
      show-overflow-tooltip
      class="none-border"
      fixed>
      <template slot="header" slot-scope="scope">
        <span>药品编码</span>
      </template>
      <template slot-scope="scope">
        <span class="link" @click.stop="onShowSimpleOperation(scope)">{{ scope.row.drugId }}</span>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      width="50"
      prop="drugId"
      show-overflow-tooltip
      fixed>
      <template slot-scope="scope">
        <el-popover
          v-if="scope.$index % 2 === 0"
          placement="top-start"
          title=""
          width="150"
          popper-class="type-icon-popper"
          trigger="hover"
          content="此行为机构数据">
          <i class="el-icon-insti" slot="reference" @click.stop="typeClick(scope)"></i>
        </el-popover>
        <el-popover
          v-else
          placement="top-start"
          title=""
          width="150"
          popper-class="type-icon-popper"
          trigger="hover"
          content="此行为平台数据">
          <i class="el-icon-platform" slot="reference" @click.stop="typeClick(scope)"></i>
        </el-popover>
      </template>
    </el-table-column>
    <el-table-column
      label="药品通用名"
      prop="genericNameCompare"
      width="140"
      fixed>
      <template slot="header" slot-scope="scope">
        <span>药品通用名</span>
        <el-popover
          :ref="'elPopover' + scope.column.property"
          v-if="searchObj.isFilter"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="true"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon fixed-column-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
          <svg-icon
            slot="reference"
            icon-class="filter"
            class="filter-icon fixed-column-selected-icon fixed-column-icon"
            v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.genericNameCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.genericNameCompareerrorTypeStr}}
        </span>
        <!-- <span v-else class="unerror-details fr">
          {{scope.row.genericNameCompareStatusStr}}
        </span> -->
      </template>
    </el-table-column>
    <el-table-column
      label="药品商品名"
      prop="tradeNameCompare"
      width="130">
      <template slot="header" slot-scope="scope">
        <span>药品商品名</span>
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
          <svg-icon
            slot="reference"
            icon-class="filter"
            class="filter-icon"
            v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.tradeNameCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.tradeNameCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <!-- <el-table-column
      width="50"
      class="none-border">
      <template slot-scope="scope">
        <span>{{ scope.row.genericNameCompareStatus }}</span>
      </template>
    </el-table-column> -->
    <el-table-column
      label="规格包装"
      column-key="filterVisible"
      prop="specCompare"
      width="140">
      <template slot="header" slot-scope="scope">
        <span>规格包装</span>
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
          <svg-icon
            slot="reference"
            icon-class="filter"
            class="filter-icon"
            v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.specCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.specCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      label="包装规格说明"
      prop="specTextCompare"
      width="140">
      <template slot="header" slot-scope="scope">
        <span>包装规格说明</span>
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
          <svg-icon
            slot="reference"
            icon-class="filter"
            class="filter-icon"
            v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.specTextCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.specTextCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <!-- <el-table-column
      width="50"
      prop="specCompareStatus">
      <template slot-scope="scope">
      </template>
    </el-table-column> -->
    <el-table-column
      label="剂型名称"
      width="150"
      prop="formNameCompare">
      <template slot="header" slot-scope="scope">
        <span>剂型名称</span>
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
          <svg-icon
            slot="reference"
            icon-class="filter"
            class="filter-icon"
            v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.formNameCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.formNameCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <!-- <el-table-column
      width="50">
      <template slot-scope="scope">
        <span>{{ scope.row.formNameCompareStatus }}</span>
      </template>
    </el-table-column> -->
    <el-table-column
      label="厂家名称"
      prop="manufacturerCompare"
      width="130">
      <template slot="header" slot-scope="scope">
        <span>厂家名称</span>
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
          <svg-icon
            slot="reference"
            icon-class="filter"
            class="filter-icon"
            v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.manufacturerCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.manufacturerCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <!-- <el-table-column
      width="50">
      <template slot-scope="scope">
        <span>{{ scope.row.manufacturerCompareStatus }}</span>
      </template>
    </el-table-column> -->
    <el-table-column
      label="批准文号"
      prop="approvalNoCompare"
      width="140">
      <template slot="header" slot-scope="scope">
        <span>批准文号</span>
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="left"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
          <svg-icon
            slot="reference"
            icon-class="filter"
            class="filter-icon"
            v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.approvalNoCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.approvalNoCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      label="国基标志"
      prop="nedFlagCompare"
      width="120">
      <template slot="header" slot-scope="scope">
        <span>国基标志</span>
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          position="left"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
          <svg-icon
            slot="reference"
            icon-class="filter"
            class="filter-icon"
            v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.nedFlagCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.nedFlagCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      v-if="showShangHai"
      label="上基标志"
      prop="shNedFlagCompare"
      width="120">
      <template slot="header" slot-scope="scope">
        <span>上基标志</span>
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
          <svg-icon
            slot="reference"
            icon-class="filter"
            class="filter-icon"
            v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.shNedFlagCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.shNedFlagCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      label="高危药品分级名称"
      prop="highRiskLvCompare"
      width="180">
      <template slot="header" slot-scope="scope">
        <span>高危药品分级名称</span>
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          position="left"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
          <svg-icon
            slot="reference"
            icon-class="filter"
            class="filter-icon"
            v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.highRiskLvCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.highRiskLvCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      label="抗菌药分级名称"
      prop="antibioticLvCompare"
      width="160">
      <template slot="header" slot-scope="scope">
        <span>抗菌药分级名称</span>
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
          <svg-icon
            slot="reference"
            icon-class="filter"
            class="filter-icon"
            v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.antibioticLvCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.antibioticLvCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      label="精麻毒放"
      prop="toxicNarcoticTypeCompare"
      width="130">
      <template slot="header" slot-scope="scope">
        <span>精麻毒放</span>
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
          <svg-icon
            slot="reference"
            icon-class="filter"
            class="filter-icon"
            v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.toxicNarcoticTypeCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.toxicNarcoticTypeCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      label="药品分类"
      prop="phaClassCompare"
      width="120">
      <template slot="header" slot-scope="scope">
        <span>药品分类</span>
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
            <svg-icon
              slot="reference"
              icon-class="filter"
              class="filter-icon"
              v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
              v-show="searchObj.isFilter"
              style="position: absolute; right: 10px;width: 20px;height: 20px;"
              @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.phaClassCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.phaClassCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      label="DDD"
      prop="dddCompare">
      <template slot="header" slot-scope="scope">
        <span>DDD</span>
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
          <svg-icon
              slot="reference"
              icon-class="filter"
              class="filter-icon"
              v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
              v-show="searchObj.isFilter"
              style="position: absolute; right: 10px;width: 20px;height: 20px;"
              @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.dddCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.dddCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      label="中西草生物制品类型名称"
      prop="classCompare"
      width="210">
      <template slot="header" slot-scope="scope">
        <span>中西草生物制品类型名称</span>
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
          <svg-icon
            slot="reference"
            icon-class="filter"
            class="filter-icon"
            v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.classCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.classCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      v-if="showShangHai"
      label="上海阳光采购平台药品统编码"
      prop="ustdCodeCompare"
      width="250">
      <template slot="header" slot-scope="scope">
        <span>上海阳光采购平台药品统编码</span>
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
            <svg-icon
              slot="reference"
              icon-class="filter"
              class="filter-icon"
              v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
              v-show="searchObj.isFilter"
              style="position: absolute; right: 10px;width: 20px;height: 20px;"
              @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.ustdCodeCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.ustdCodeCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      label="集采标志"
      prop="ncpFlagCompare"
      width="170">
      <template slot="header" slot-scope="scope">
        <span>集采标志</span>
        <!-- <el-popover
          :ref="'elPopover' + scope.column.property"
          v-if="searchObj.isFilter"
          trigger="click"
          placement="left"
          popper-class="governance-poper"
          :visible-arrow="true"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon fixed-column-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
          <svg-icon
            slot="reference"
            icon-class="filter"
            class="filter-icon fixed-column-selected-icon fixed-column-icon"
            v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover> -->
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
            <svg-icon
              slot="reference"
              icon-class="filter"
              class="filter-icon"
              v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
              v-show="searchObj.isFilter"
              style="position: absolute; right: 10px;width: 20px;height: 20px;"
              @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.ncpFlagCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.ncpFlagCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      label="国家贯标码"
      prop="nhsaCodeCompare"
      width="140">
      <template slot="header" slot-scope="scope">
        <span>国家贯标码</span>
        <!-- <el-popover
          :ref="'elPopover' + scope.column.property"
          v-if="searchObj.isFilter"
          trigger="click"
          placement="left"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon fixed-column-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
          <svg-icon
            slot="reference"
            icon-class="filter"
            class="filter-icon fixed-column-selected-icon fixed-column-icon"
            v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover> -->
        <el-popover
          :ref="'elPopover' + scope.column.property"
          trigger="click"
          placement="top-start"
          popper-class="governance-poper"
          :visible-arrow="false"
          :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <flat-filter
            :ref="'filter' + scope.$index"
            :show-filter="searchObj.isFilter"
            :filter-options="filterOptions"
            :position="filterCascaderPosition(scope)"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="!filterChangeIcon[scope.column.property + scope.$index]"
            v-show="searchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
            <svg-icon
              slot="reference"
              icon-class="filter"
              class="filter-icon"
              v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
              v-show="searchObj.isFilter"
              style="position: absolute; right: 10px;width: 20px;height: 20px;"
              @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span class="fl">{{ scope.row.nhsaCodeCompare }}</span>
        <span v-if="isShowErrorDetails(scope)" class="error-details fr">
          {{scope.row.nhsaCodeCompareerrorTypeStr}}
        </span>
      </template>
    </el-table-column>
    <simple-check-report-vue
      v-if="dialogVisible"
      :dialog-visible.sync="dialogVisible"
      :drug-id="currentRowBySimple.drugId"
      :origin-table-data="tableDataInitial"
      :origin-data="currentRowBySimple"
      :grade-options="gradeOptions"
      :show-operation="false" />
  </win-table>
</template>
<script>
  import {
    FILTER_OPTIONS,
    GRADE_OPTIONS,
    TABLE_DATA_ORIGIN
  } from '@/utils/dm/institutionGovernanceReport/data'
  import {
    handleTableData
  } from '@/utils/dm/institutionGovernanceReport/handle.js'
  import { getMatchRecordListApi } from '@/api/dm/institutionGovernanceReport'
  import FilterCascader from './FilterCascader.vue'
  import GradeCascader from './GradeCascader.vue'
  import FlatFilter from '@/views/dm/institutionGovernanceReport/components/FlatFilter'
  import SimpleCheckReportVue from '@/views/dm/platformGovernanceReport/components/SimpleCheckReport.vue'
  export default {
    name: 'institutionMatchReport',
    components: {
      GradeCascader,
      FilterCascader,
      FlatFilter,
      SimpleCheckReportVue
    },
    props: {
      searchObj: {
        required: true,
        type: Object
      },
      applyId: {
        type: Number,
        required: true
      }
    },
    filters: {
      noMapReason(reason) {
        if (reason) {
          return reason.join(',')
        }
      }
    },
    computed: {
      gradeIcon: function () {
        return function (status) {
          if (Number(status) === 1) {
            return 'kc-empty'
          } else if (Number(status) === 2) {
            return 'duihao2'
          } else if (Number(status) === 3) {
            return 'wenhao'
          }
        }
      },
      noMapReasonList: function () {
        return function (list) {
          if (list && list.length > 1) {
            return [list[0], '...']
          }
        }
      },
      // 筛选模式显示的位置
      filterCascaderPosition(scope) {
        return function(scope) {
          if (scope.$index > 5) {
            return 'left'
          } else {
            return 'right'
          }
        }
      },
      // 是否显示风险级别或问题类型的具体详情
      isShowErrorDetails: function () {
        return function (scope) {
          const riskLevel = scope.row[scope.column.property + 'riskLevel']
          if ((riskLevel === '3' || riskLevel === '4') && scope.$index % 2 === 0) {
            // 只有机构那一条且满足条件数据显示
            return true
          } else {
            return false
          }
        }
      },
      // 判断是否是上海的机构
      showShangHai() {
        const { provinceCode } = this.$store.getters.dept
        if (provinceCode === '310000') {
          return true
        } else {
          return false
        }
      }
    },
    watch: {
      searchObj: {
        async handler(newVal, oldVal) {
          // await this.fetchData()
        },
        deep: true
      },
      async applyId(newVal, oldVal) {
        if (oldVal) {
          this.searchObj.applyId = this.applyId
          await this.fetchData()
        }
      }
    },
    data() {
      return {
        filterOptions: this.$deepClone(FILTER_OPTIONS),
        gradeOptions: this.$deepClone( GRADE_OPTIONS),
        filterVisible: false,
        spanArr: [],
        pos: 0,
        tableDom: null,
        currentRow: null,
        currentScope: null, // 筛选模式下点击的当前scope
        tableData: [],
        initTableData: [],
        tableDataInitial: [],
        filterChangeIcon: {}, // 用来切换每个对应的筛选图标
        filterRefsArr: [], // 用来存储筛选模式组件
        dialogVisible: false,
        currentRowBySimple: null
      }
    },
    async mounted() {
      // 获取需要绑定的table
      this.tableDom = this.$refs.matchReportTableRef.bodyWrapper
      this.tableDom.addEventListener('scroll', () => {
        // 滚动距离
        let scrollTop = this.tableDom.scrollTop
        // 变量windowHeight是可视区的高度
        let windowHeight = this.tableDom.clientHeight || this.tableDom.clientHeight
        // 变量scrollHeight是滚动条的总高度
        let scrollHeight = this.tableDom.scrollHeight || this.tableDom.scrollHeight
        /*if (scrollTop + windowHeight === scrollHeight) {
        // 获取到的不是全部数据 当滚动到底部 继续获取新的数据
        if (!this.allData) this.getMoreLog()
        console.log('scrollTop', scrollTop + 'windowHeight', windowHeight + 'scrollHeight', scrollHeight)
        } */
        if (this.currentScope) {
          const filterName = 'filter' + this.currentScope.$index
          const refName = 'elPopover' + this.currentScope.column.property
          this.$nextTick(() => {
            if (this.$refs[filterName]) {
              this.$refs[filterName].onFilterClick(this.currentScope)
              this.$refs[refName] && this.$refs[refName].doClose()
            }
          })
          this.onHidePopover(this.currentScope)
        }
      })
      this.$nextTick().then(() => {
        this.appendDom('.fixed-column-icon')
      })
    },
    async created() {
      this.searchObj.applyId = this.applyId
      this.filterRefsArr = []
      await this.fetchData()
      // await this.fetchTableData()
    },
    methods: {
      // 解决表格fixed之后，筛选模式弹框没出现的问题
      appendDom(className){
        let nodes = document.querySelectorAll(className)
        if(!nodes.length) return
        let fristbtnparentDom = nodes[0].parentNode
        let lastbtnparentDom = nodes[1].parentNode
        lastbtnparentDom.innerHTML = ''
        if(fristbtnparentDom.childNodes.length){
          for(let i=0; fristbtnparentDom.childNodes[i++];){
            lastbtnparentDom.appendChild(fristbtnparentDom.childNodes[0])
            // lastbtnparentDom.appendChild(fristbtnparentDom.childNodes[0])
          }
        }
      },
      async fetchData(type) {
        let obj = this.$deepClone(this.searchObj)
        if (type === 'reset') {
          // 筛选重置
          obj.searchValue = ''
        }
        let res = await getMatchRecordListApi(obj)
        if (res.code === 200) {
          let tableData = res.data.rows || []
          this.tableDataInitial = res.data.rows || []
          this.initTableData = handleTableData(tableData)
          this.initTableData.forEach(item => {
            item.isFolded = true // 打分的图标初始时为折叠状态
          })
          this.tableData = this.$deepClone(this.initTableData)
          for (let i = 0; i < this.tableData.length; i++) {
            let item = this.tableData[i]
            let nextItem = this.tableData[i + 1]
            if (nextItem) {
              if (item.drugId === nextItem.drugId && i % 2 === 1) {
                item.isStripe = false
                nextItem.isStripe = false
              } else if (item.drugId === nextItem.drugId && i % 4 === 0) {
                item.isStripe = true
                nextItem.isStripe = true
              } else if (item.drugId === nextItem.drugId && i % 4 === 1) {
                item.isStripe = false
                nextItem.isStripe = false
              }
            }
          }
          this.setMergeArr(this.tableData)
          this.$emit('matchTable', res.data)
        }
      },
      async fetchTableData() {
        const tableData = this.$deepClone(TABLE_DATA_ORIGIN)
        let data = handleTableData(tableData)
        data.forEach(item => {
          item.isFolded = true // 打分的图标初始时为折叠状态
        })
        this.tableData = this.$deepClone(data)
        this.setMergeArr(this.tableData)
      },
      setMergeArr(data) {　
        this.spanArr = []
        for (var i = 0; i < data.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
          } else {
              // 判断当前元素与上一个元素是否相同
            if (data[i].drugId === data[i - 1].drugId) {
              this.spanArr[this.pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              this.pos = i
            }
          }
        }
      },
      getSummaries({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) { // 设置需要合并的列（索引）
          if (rowIndex % 2 === 0) { // 设置合并多少行
            return {
              rowspan: 2, // 需要合并的行数
              colspan: 1 // 需要合并的列数，设置为0，不显示该列
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            }
          }
        }
        // else if (columnIndex % 2 === 0) { // 合并第二列所有行
        //   const _row = this.spanArr[rowIndex]
        //   const _col = _row > 0 ? 1 : 0
        //   return {
        //     rowspan: _row,
        //     colspan: _col
        //   }
        // }
      },
      // 表头渲染
      // renderFilter(creatElement, { column, $index }) {
      //   return (
      //     <span class='el-dropdown-link' style="color:#ffffff"> 等级 <i class='el-icon-sort el-icon--right'></i></span>
      //   )
      // },
      headerGradeInfo({ row, column, rowIndex, columnIndex }) {
        if (columnIndex % 2 === 1 && columnIndex !== 0) {
          // return 'none-border'
        }
      },
      gradeInfo({ row, column, rowIndex, columnIndex }) {
        let color = this.tableDataHandle(row, column, rowIndex, columnIndex)
        let hover = ''
        // if (this.currentRow && this.currentRow.drugId === row.drugId) {
        //   hover = 'hover'
        // } else {
        //   hover = ''
        // }
        return color + ' ' + hover
      },
      // 当单元格 hover 进入时会触发该事件
      cellMouseEnter(row, column, cell, event) {
        this.currentRow = row
      },
      cellMouseLeave(row, column, cell, event) {
        this.currentRow = null
      },
      // 表格行
      tabRowClassName({ row, rowIndex }) {
        if (row.isStripe) {
          return 'stripe-row'
        } else {
          return ''
        }
      },
      tableDataHandle(row, column, rowIndex, columnIndex) {
        for (let i = 0; i < this.tableData.length ; i++) {
          let item = this.tableData[i]
          item.filterVisible = false
          const {
            genericNameCompareStatus,
            specCompareStatus,
            formNameCompareStatus,
            manufacturerCompareStatus,
            approvalNoCompareStatus
          } = item
          if (column.property && column.property !== 'drugId') {
            const propName = row[column.property + 'riskLevel']
            // 风险级别为风险项显示红色，为优化项显示黄色
            if (propName === '3' && i === rowIndex) {
              item.className = 'error-color'
              if (rowIndex % 2 === 0) {
                return 'error-color error-reason'
              }
              return 'error-color'
            } else if (propName === '4' && i === rowIndex) {
              item.className = 'warning-color'
              if (rowIndex % 2 === 0) {
                return 'warning-color warning-reason'
              }
              return 'warning-color'
              // nextItem.className = 'warning-color'
              
            } else if ((propName === '2' || propName === '1')  && i === rowIndex) {
              return ''
            }
            // 当风险级别为风险项和优化项的时候，机构那一行显示详情
          }
          // let nextItem = this.tableData[i + 1]
          // 药品通用名
          /* if (genericNameCompareStatus === '3' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'error-color'
            // nextItem.className = 'error-color'
            return 'error-color'
          } else if (genericNameCompareStatus === '1' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'warning-color'
            // nextItem.className = 'warning-color'
            return 'warning-color'
          } else if ((genericNameCompareStatus === '2' || genericNameCompareStatus === '4')  && i === rowIndex && columnIndex % 2 === 1) {
            return ''
          }
          // 规格
          if (specCompareStatus === '3' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'error-color'
            // nextItem.className = 'error-color'
            return 'error-color'
          } else if (specCompareStatus === '1' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'warning-color'
            // nextItem.className = 'warning-color'
            return 'warning-color'
          } else if ((specCompareStatus === '2' || specCompareStatus === '4')  && i === rowIndex && columnIndex % 2 === 1) {
            return ''
          }
          // 剂型名称
          if (item.formNameCompareStatus === '3' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'error-color'
            // nextItem.className = 'error-color'
            return 'error-color'
          } else if (item.formNameCompareStatus === '3' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'warning-color'
            // nextItem.className = 'warning-color'
            return 'warning-color'
          }
          // 厂商
          if (item.manufacturerCompareStatus === '3' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'error-color'
            // nextItem.className = 'error-color'
            return 'error-color'
          } else if (item.manufacturerCompareStatus === '1' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'warning-color'
            // nextItem.className = 'warning-color'
            return 'warning-color'
          }
          // 批准文号
          if (item.approvalNoCompareStatus === '3' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'error-color'
            // nextItem.className = 'error-color'
            return 'error-color'
          } else if (item.approvalNoCompareStatus === '1' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'warning-color'
            // nextItem.className = 'warning-color'
            return 'warning-color'
          }
          */
        }
      },
      // 筛选模式使用的组件popover隐藏时的事件
      onHidePopover(scope) {
        const filterName = 'filter' + scope.$index
        this.$nextTick(() => {
          this.tableData.forEach(item => {
            item.isFolded = true // 打分的图标初始时为折叠状态
          })
          if (this.$refs[filterName] && this.$refs[filterName].filterOptions) {
            this.$refs[filterName].filterOptions.forEach(option => {
              option.showChildren = false
            })
          }
          if (this.filterOptions) {
            this.filterOptions.forEach(option => {
              option.showChildren = false
            })
          }
          this.currentScope = null
        })
      },
      // 筛选模式使用的组件popover显示时的事件
      onShowPopover(scope) {
        const filterName = 'filter' + scope.$index
        this.$nextTick(() => {
          this.$refs['elPopover' + scope.column.property].updatePopper()
          if (this.$refs[filterName]) {
            this.$refs[filterName].checkList = []
            this.$refs[filterName].isShowOptions = true
          }
        })
      },
      // 筛选模式，点击筛选图标事件
      onFilterClick(scope) {
        this.currentScope = scope
        const filterName = 'filter' + scope.$index
        this.filterRefsArr.push(filterName)
        this.$nextTick(() => {
          if (this.$refs[filterName]) {
            // console.log(this.$refs[filterName])
            this.$refs[filterName].onFilterClick(scope)
          }
        })
      },
      // 打分和filter下拉项关闭
      async onCloseFilter(selectObj, scope) {
        // 第二版本点击取消按钮的时候，selectObj为undefined
        let len1 = 0
        let len2 = 0
        let riskLevelCheckList = []
        let problemCheckList = []
        if (selectObj) {
          riskLevelCheckList = selectObj.riskLevelCheckList
          problemCheckList = selectObj.problemCheckList
        }
        if (selectObj && riskLevelCheckList && riskLevelCheckList.length) {
          len1 = selectObj.riskLevelCheckList.length
        }
        if (selectObj && problemCheckList && problemCheckList.length) {
          len2 = selectObj.problemCheckList.length
        }
        if (len1 > 0 || len2 > 0) {
          this.$set(this.filterChangeIcon, scope.column.property + scope.$index, true)
        }
        this.tableData.forEach(item => {
          item.isFolded = true // 打分的图标初始时为折叠状态
        })
        // const refName = 'popover' + scope.column.property + scope.$index
        const refName = 'elPopover' + scope.column.property
        this.$nextTick(() => {
          this.$refs[refName] && this.$refs[refName].doClose()
        })
        let iconStatus = false
        if (this.filterChangeIcon[scope.column.property + scope.$index]) {
          // 表示当前的图标为实心的，即上一次筛选条件是不为空的
          iconStatus = true
        }
        this.filterVisible = false
        if (len1 === 0 && len2 === 0) {
          // 第一版本的时候什么都没选择的时候和第二版本点击取消按钮的时候，不调接口，直接关闭
          // 第二版本选择问号或叉号的时候
          if ((!iconStatus && selectObj && !selectObj.level) ||(!iconStatus && !selectObj)) {
            // 第一版本的时候什么都没选择的时候和第二版本点击取消按钮的时候，不调接口，直接关闭
            return
          } else if (!iconStatus && selectObj) {
            //  && (selectObj.level === '1' || selectObj.level === '2' || selectObj.level === '1,2')
            // 第二版本选择问号或叉号的时候
            this.$set(this.filterChangeIcon, scope.column.property + scope.$index, true)
          }
        } else if (len1 === 0 && len2 === 0 && iconStatus) {
          this.$set(this.filterChangeIcon, scope.column.property + scope.$index, false)
        }
        let propertyLen = scope.column.property.length
        // this.searchObj.filterFieldName = scope.column.property
        this.searchObj.filterErrorTypeList = []
        this.searchObj.filterRiskLevelList = []
        this.searchObj.filterRiskLevelList = selectObj.riskLevelCheckList
        this.searchObj.filterErrorTypeList = selectObj.problemCheckList
        this.searchObj.pageNum = 1
        // 过滤条件叠加
        let obj = {
          filterFieldName: [],
          filterErrorTypeList: [],
          filterRiskLevelList: []
        }
        let isExited = false
        // 1.如果之前筛选条件包含某一列，现在这一列又筛选成为空条件；2.修改了某一列的筛选条件
        for (let i = 0; i < this.searchObj.filterList.length; i++) {
          let item = this.searchObj.filterList[i]
          if (item.filterFieldName === scope.column.property) {
            if (problemCheckList.length === 0 && riskLevelCheckList.length === 0 && !selectObj.level) {
              // 删除该项
              this.searchObj.filterList.splice(i, 1)
            } else {
              //修改该项
              if (selectObj.level) {
                if (Array.isArray(selectObj.level)) {
                  item.evalResultList = selectObj.level
                } else {
                  if (selectObj.level.split(',').length > 1) {
                    let arr = []
                    arr = selectObj.level.split(',')
                    item.evalResultList = arr
                  } else {
                    item.evalResultList = [selectObj.level]
                  }
                }
              }
              item.filterErrorTypeList = selectObj.problemCheckList
              item.filterRiskLevelList = selectObj.riskLevelCheckList
            }
            isExited = true
            break
          }
        }
        if (Array.isArray(selectObj.level)) {
          obj.evalResultList = selectObj.level
        } else {
          if (selectObj.level.split(',').length > 1) {
            let arr = []
            arr = selectObj.level.split(',')
            obj.evalResultList = arr
          } else {
            obj.evalResultList = [selectObj.level]
          }
        }
        obj.filterFieldName = scope.column.property
        obj.filterErrorTypeList = selectObj.problemCheckList
        obj.filterRiskLevelList = selectObj.riskLevelCheckList
        if (!isExited) {
          this.searchObj.filterList.push(obj)
        }
        await this.fetchData()
        // scope.column.columnKey = false
      },
      // 筛选重置
      async resetFilterHandle() {
        this.filterChangeIcon = {}
        this.searchObj.filterFieldName = ''
        this.searchObj.pageNum = 1
        this.searchObj.filterErrorTypeList = []
        this.searchObj.filterRiskLevelList = []
        this.searchObj.filterList = []
        for (let filter of this.filterRefsArr) {
          this.$refs[filter].onReset()
        }
        await this.fetchData('reset')
      },
      onShowSimpleOperation(scope) {
        this.dialogVisible = true
        this.currentRowBySimple = scope.row
      }
    }
  }
</script>
<style lang="scss" scoped>
.match-report-table {
  .el-table__body tr.hover-row>td, .el-table__body tr > td.hover {
    background: $tableHoverColor !important;
  }

::v-deep.el-table--enable-row-hover .el-table__body tr:hover>td {
  background: $tableHoverColor !important;
 }
 ::v-deep .stripe-row {
    background-color: #fafafa;
  }
  ::v-deep.el-table__fixed {
    /* height:auto !important;  */
    height: calc(100% - 12px) !important;
    bottom: 16px;
  }
  .icon-class {
    display: inline-block;
    position: absolute;
    right: 0px;
    transform: translateY(-50%);
    color: #2B60F8;
    cursor: pointer;
  }
  .filter-icon {
    cursor: pointer;
    &::after {
      content: '4444';
      display: inline-block;
      width: 12px;
      height: 12px;
      background: pink;
    }
  }
  ::v-deep td {
    padding: 3px 5px !important;
    position: relative;
    .el-icon-insti {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      &:before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        background-image: url('../../../../assets/images/insti-icon.png');
        background-repeat: no-repeat;
      }
    }
    .el-icon-platform {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      &:before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        background-image: url('../../../../assets/images/plat-icon.png');
        background-repeat: no-repeat;
      }
    }
  }
  ::v-deep th.none-border, ::v-deep td.none-border {
    border-right: none !important;
  }
  ::v-deep td.error-color {
    background-color: #FFDBE1;
  }
  ::v-deep td.warning-color {
    background-color: #FFE9D1;
  }
  ::v-deep td .link {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: #4B56F2;
    }
  }
  ::v-deep .error-reason, ::v-deep .warning-reason {
    .cell {
      position: relative;
      .error-details {
        display: inline-block;
        padding: 5px !important;
        color: #2B60F8;
        background-color: #D7E3FA;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
      }
    }
  }
}
</style>
