<!--机构治理报告-->
<template>
  <div style="height: 100%;">
  <el-container :key="applyId" v-if="applyId && !enlarge && hasReport" v-cloak class="layout-container insti-report-container">
    <el-aside class="insti-report-aside box-shadow-radius">
      <section class="health-index-section clearfix">
        <div class="title-header-container">
          <div>
            <span class="title-header"></span>
            <h4 class="title-text">
              健康指数
            </h4>
          </div>
          <el-button class="" type="text" @click="onShowHistoryVersion">查看历史版本</el-button>
        </div>
        <p>当前版本：<span>{{ scoreDataObj.simpleVersion }}</span></p>
        <div class="ring-chart-container">
          <!-- <p>当前版本：<span>{{ scoreDataObj.simpleVersion }}</span></p> -->
          <section style="margin-bottom: 20px;width: 100%;" id="ring-chart-section">
            <div id="ring-chart" :style="ringChartStyle"></div>
            <p style="text-align: center;">
              <span>与上次相比：</span>
              <span style="color: #2B60F8;font-size: 16px;">{{ scoreDataObj.indexCompareAbsoluteValue | indexCompareAbsoluteValue(scoreDataObj) }}</span>
              <svg-icon v-if="showTrendIcon" :icon-class="trendIcon" class="babu-svg" />
            </p>
          </section>
        </div>
      </section>
      <section class="radar-section" id="radar-chart-section">
        <div class="title-header-container">
          <div>
            <span class="title-header"></span>
            <h4 class="title-text">
              雷达分析
            </h4>
          </div>
        </div>
        <div class="radar-wrap">
          <div id="radar-chart" class="radar-chart" :style="radarChartStyle"></div>
        </div>
      </section>
      <history-list
        v-show="isShowHistoryList"
        ref="historyRef"
        :apply-id="applyId"
        :data-from="dataFrom"
        @changeVersion="onChangeVersion" />
    </el-aside>
    <el-container class="layout-container insti-report-content-container">
      <el-header class="chart-container box-shadow-radius clearfix">
        <section class="match-item-section clearfix box-shadow-radius" style="position: relative;">
          <div class="title-header-container" style="display: flex;justify-content: space-between;">
            <div>
              <span class="title-header"></span>
              <h4 class="title-text">
                匹配情况
              </h4>
            </div>
            <!-- <section v-if="dataFrom === 'self'" class="apply-report-section" style="display:inline-block;position: absolute;right: 0;top:20px"> -->
              <el-button v-if="dataFrom === 'self'" type="primary" @click="onApplyReport" size="small" style="margin-right: 8px;">申请报告</el-button>
            <!-- </section> -->
          </div>
          <!--饼图-->
          <div id="pie-chart" style="height: 186px;width: 70%" class="fl"></div>
          <!--手动生成饼图的legend，因为echarts自带的不太好控制样式和事件-->
          <div class="pie-legend-wrap fr" style="width: 30%">
            <div class="match-legend pie-legend" :class="{active: isSelectedMatch}" @click="onLegendClick('match')">
              <span class="match-circle legend-circle"></span>
              <span class="match-text legend-text">匹配项</span>
            </div>
            <div class="no-match-legend pie-legend" :class="{active: !isSelectedMatch}" @click="onLegendClick('no-match')">
              <span class="no-match-circle legend-circle"></span>
              <span class="no-match-text legend-text">未匹配项</span>
            </div>
          </div>
        </section>
        <section class="no-match-item-section box-shadow-radius">
          <transition name="component-fade" mode="out-in">
            <component
              v-if="applyId"
              :is="which_to_show"
              :applyId="applyId"
              @matchSitutionsObj="getmatchSitutionsObj"
              @noMatchObj="getNoMatchObj"></component> 
          </transition>
        </section>
        <!-- <section v-if="dataFrom === 'self'" class="apply-report-section">
          <el-button type="primary" @click="onApplyReport">申请治理报告</el-button>
        </section> -->
      </el-header>
      <el-main class="box-shadow-radius" style="padding: 0;">
        <el-container class="layout-container table-container">
          <el-header class="report-table-header">
            <div class="title-header-container">
              <span class="title-header"></span>
              <h4 class="title-text">
                {{ searchTitle }}
                <svg-icon
                  icon-class="wenhao"
                  fill-color="#0073E9"
                  style="width: 18px;height: 18px;cursor: pointer;position: absolute;top: 50%;transform: translateY(-50%);"
                  @click="onShowSource" />
              </h4>
            </div>
            <el-form :model="tableSearchObj" :inline="true" class="search-form">
              <el-form-item v-if="!isSelectedMatch" label="未匹配原因：">
                <el-select v-model="tableSearchObj.errorCodeList" multiple collapse-tags placeholder="请选择">
                  <el-option
                    v-for="item in errorOptions"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input v-model="tableSearchObj.searchValue" clearable placeholder="输入编号/药品通用名" />
              </el-form-item>
              <el-form-item v-if="isSelectedMatch" label="只看问题字段">
                <el-switch
                  v-model="tableSearchObj.errorProblemnFlag"
                  active-color="#2c5ff9"
                  inactive-color="#b5ccf5"
                  @change="errorFilterChange">
                </el-switch>
              </el-form-item>
              <el-form-item v-if="isSelectedMatch" label="筛选">
                <el-switch
                  v-model="tableSearchObj.isFilter"
                  active-color="#2c5ff9"
                  inactive-color="#b5ccf5"
                  @change="isFilterChange">
                </el-switch>
              </el-form-item>
              <el-form-item v-if="isSelectedMatch">
                <el-button size="small" type="primary" @click="onFilterReset">筛选重置</el-button>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="onSearchReportTable">查询</el-button>
              </el-form-item>
              <el-form-item>
                <el-button size="small" icon="el-icon-zoom-in" type="primary" @click="onEnlarge">放大</el-button>
              </el-form-item>
              <!-- <el-form-item>
                <el-button size="small" type="primary">下载报告</el-button>
              </el-form-item> -->
            </el-form>
          </el-header>
          <el-main style="padding: 5px 15px 0 15px;">
            <no-match-report
              v-if="!isSelectedMatch"
              ref="noMatchTable"
              :search-obj="tableSearchObj"
              :apply-id="applyId"
              @noMatchTable="getNoMatchTable"/>
            <match-report
              v-if="isSelectedMatch && applyId"
              ref="matchTable"
              :search-obj="tableSearchObj"
              :apply-id="applyId"
              @matchTable="getMatchTable"/>
          </el-main>
          <el-footer style="height: 42px;" class="clearfix">
            <div class="fl" style="line-height: 42px;">
              <i class="el-icon-warning" style="color: #94AFF7;vertical-align: middle;"></i>
              <span style="margin-left: 3px;font-size: 12px;color: #999;">系统所展示药品信息，仅供参考，不作为使用依据</span>
            </div>
            <pagination
              v-show="total > 0"
              :total="total"
              :page.sync="tableSearchObj.pageNum"
              :limit.sync="tableSearchObj.pageSize"
              class="fr"
              @pagination="getTableHandle"/>
          </el-footer>
          <source-dialog-vue :dialog-visible.sync="dialogVisible" />
        </el-container>
      </el-main>
    </el-container>
  </el-container>
  <el-container class="layout-container table-container enlarge-container" v-else-if="enlarge" v-cloak>
    <!--放大后的页面显示组件-->
    <enlarge-component-vue
      :enlarge.sync="enlarge"
      :is-selected-match="isSelectedMatch"
      :apply-id="applyId"
      :error-options="errorOptions"
      :search-title="searchTitle"
      @errorFilterChange="errorFilterChange"
      @onSearchReportTable="onSearchReportTable" />
  </el-container>
  <el-container v-else-if="!applyId && !enlarge && !hasReport" v-cloak class="layout-container no-data-container empty-layout-container">
    <el-main class="no-data-main">
      <div class="none-data-wrap">
        <h3 class="no-data-title">线上治理更省心，机构治理报告 </h3>
        <span class="no-data-subtext">致力于底层药物数据的治理与管理</span>
        <el-button type="primary" @click="onApplyReport">申请报告</el-button>
      </div>
    </el-main>
    <!-- <section class="apply-report-section">
      <el-button type="primary" @click="onApplyReport">申请治理报告</el-button>
    </section> -->
  </el-container>
</div>
</template>
<script>
import Cookies from "js-cookie"
import {
  getLatestRecord,
  getMatchSituationApi, // 匹配情况
  applyReportApi, // 申请治理报告
  getScoreApi  // 健康指数、雷达分析
} from '@/api/dm/institutionGovernanceReport'
import {
  getPlatformMatchSituationApi, // 匹配情况(平台)
  applyPlatformReportApi, // 申请治理报告(平台)
  getPlatformScoreApi  // 健康指数、雷达分析(平台)
} from '@/api/dm/platformGovernanceReport'
import {
  FILTER_OPTIONS,
  GRADE_OPTIONS,
  TABLE_DATA,
  SCORE_DATA_OBJ,
  NO_MATCH_TABLE_SEARCH_OBJ
} from '@/utils/dm/institutionGovernanceReport/data'
import {
  buildReportPieChart,
  radarChart,
  createPieChart
} from '@/utils/pieChart.js'
import {
  chartsDataHandle,
  handleTableData
} from '@/utils/dm/institutionGovernanceReport/handle.js'
import FilterCascader from './components/FilterCascader'
import GradeCascader from './components/GradeCascader'
import HistoryList from './components/HistoryList'
import MathcItem from './components/MatchItem'
import NoMatchItem from './components/NoMatchItem'
import NoMatchReport from './components/NoMatchReportTable' // 未匹配项审核报告模块表格
import MatchReport from './components/MatchReportTable' // 匹配项审核报告模块表格
import EnlargeComponentVue from "./components/EnlargeComponent.vue"
import SourceDialogVue from './components/SourceDialog.vue'
export default {
  name: 'InstitutionGovernanceReport',
  components: {
    FilterCascader,
    GradeCascader,
    HistoryList,
    MathcItem,
    NoMatchItem,
    NoMatchReport,
    MatchReport,
    EnlargeComponentVue,
    SourceDialogVue
  },
  props: {
    dataFrom: {
      type: String,
      // self(机构本身),other(来源于平台)
      default: 'self'
    },
    applyIdFromOther: {
      type: Number
    }
  },
  filters: {
    indexCompareAbsoluteValue(value, scoreDataObj) {
      if (scoreDataObj.indexCompareTrend === 0) {
        return '持平'
      } else if (scoreDataObj.indexCompareTrend === -1 || scoreDataObj.indexCompareTrend === 1) {
        if (value) {
          if (value.indexOf('%') > -1) {
            return value.slice(0, value.length - 1) + '分'
          } else {
            return value + '分'
          }
        }
      } else {
        return value
      }
    },
    indexCompareTrend(value) {
      // 指数比较趋势 -1:下降 0:持平 1:上升 空值:无上次数据
      if (value === -1) {
        return '下降'
      } else if (value === 0) {
        return '持平'
      } else if (value === 1) {
        return '上升'
      } else {
        return '无上次数据'
      }
    },
    compareVal(val) {
      if (val.indexOf('%') > -1) {
        return val.slice(0, val.length - 1) + '分'
      }
    }
  },
  computed: {
    showTrendIcon() {
      const {indexCompareTrend} = this.scoreDataObj
      if (indexCompareTrend === -1 || indexCompareTrend === 1) {
        return true
      } else {
        return false
      }
    },
    trendIcon() {
      const {indexCompareTrend} = this.scoreDataObj
      if (indexCompareTrend === -1)  {
        return 'down'
      } else if (indexCompareTrend === 1) {
        return 'up'
      }
    }
  },
  data() {
    return {
      hasReport: false, // 是否有治理报告
      enlarge: false, // 是否放大
      loading: false,
      ringChartStyle: {
        width: '70%',
        height: '100px'
      },
      radarChartStyle: {
        width: '100%',
        height: '160px'
      },
      radarRadius: '75%',
      searchObj: {},
      errorOptions: [],
      tableSearchObj: this.$deepClone(NO_MATCH_TABLE_SEARCH_OBJ),
      currentPage: 1,
      total: 0,
      pageSize: 10,
      tableData: [],
      healthIndexChart: null, // 健康指数的图表
      radarChart: null, // 雷达图图表
      applyId: null, // 该机构最近一次的申请报告id
      scoreDataObj: this.$deepClone(SCORE_DATA_OBJ), // 左侧健康指数、雷达图
      matchSituationData: [], // 匹配情况数据（饼图）
      isSelectedMatch: true, // 匹配情况（饼图）legend点击选中，初始化的时候默认选中匹配项
      which_to_show: MathcItem, // 匹配情况切换显示对应的组件
      isShowHistoryList: false, // 是否显示历史版本
      searchTitle: '报告明细',
      dialogVisible: false
    }
  },
  watch: {
    applyId: async function (newVal, oldVal) {
      if (oldVal && oldVal !== '') {
        // 历史版本中切换不同的已发布的治理报告, 选中项为匹配项
        this.onLegendClick('match')
        await this.allFetchData()
      }
    },
    enlarge: async function (newVal, oldVal) {
      if (!newVal && oldVal) {
        await this.allFetchData()
      }
    }
  },
  async mounted() {
    let applyId = Cookies.get('applyId')
    this.hasReport = true
    if (this.dataFrom === 'self' && !applyId) {
      // 机构本身应运场景
      await this.fetchLatestRecord()
    } else if (this.dataFrom === 'other') {
      // 平台应用场景
      this.applyId = this.applyIdFromOther
    } else if (this.dataFrom === 'self' && applyId) {
      // 从首页事件记录链接跳转过来的
      this.applyId = Number(applyId)
    }
    await this.allFetchData()
    // 这样写不执行resize
    // window.onresize = function () {
    //   chartDom.resize()
    //   radarDom.resize()
    // }
  },
  async created() {
    // await this.fetchTableData()
    // console.log(Cookies.get('applyId'))
  },
  destoryed() {
    Cookies.remove('applyId')
  },
  deactivated() {
    Cookies.remove('applyId')
  },
  methods: {
    // 不同分辨率下图表大小做调整
    chartSizeChange(dom) {
      let windowWidth = window.innerWidth
      let windowHeight = window.innerHeight
      let ringDom = document.getElementById('ring-chart')
      let ringContainerDom = document.getElementsByClassName('ring-chart-container')[0]
      let ringWidth = ringDom && ringDom.offsetWidth
      
      // ringDom.offsetHeight = ringWidth
      let size = windowWidth <= 1280 ? 110 : ringWidth // 这个方法暂且不用
      // 获取图表的宽度
      if (windowWidth < 768) {
        // xs
        size = 100
      } else if (windowWidth >= 768 && windowWidth < 992) {
        // sm
        size = 100
      } else if (windowWidth >= 992 && windowWidth < 1200) {
        // md
        size = 100
      } else if (windowWidth >= 1200 && windowWidth < 1280) {
        // lg
        size = 100
      } else if(windowWidth >= 1280 && windowWidth < 1536) {
        // xl
        size = 120
      } else if (windowWidth >= 1536 && windowWidth < 1920) {
        size = 180
      } else if (windowWidth >= 1920) {
        size= 200
      }
      this.ringChartStyle.width = size + 'px'
      this.ringChartStyle.height = size + 'px'
      ringContainerDom.style.alignSelf = 'center'
      let ringContainerWidth = ringContainerDom && ringContainerDom.offsetWidth
      if (size !== ringWidth && windowWidth <= 1536) {
        ringDom.style.marginLeft = (Math.abs(156 - size)) / 2 + 'px'
      }
      // console.log(ringWidth)
    },
    // 不同分辨率下图表大小做调整
    radarChartSizeChange(dom) {
      let windowWidth = window.innerWidth
      let asideHeight = document.getElementsByClassName('insti-report-aside')[0].offsetHeight
      let ringSectionHeight = document.getElementsByClassName('health-index-section')[0].offsetHeight
      let height = asideHeight - ringSectionHeight - 32 - 10 - 2 - 20
      let ringDom = document.getElementById('radar-chart')
      let ringContainerDom = document.getElementsByClassName('radar-section')[0]
      let ringWidth = ringDom && ringDom.offsetWidth
      let size = height > ringWidth ? ringWidth : height
      if (windowWidth <= 1280 && size > 150) {
        size = 160
      }
      // ringDom.offsetHeight = ringWidth
      this.radarChartStyle.width = size + 'px'
      this.radarChartStyle.height = size + 'px'
      // ringContainerDom.style.alignSelf = 'center'
      // console.log(height)
    },
    // 所有的需要调接口的函数
    async allFetchData() {
      if (!this.applyId) {
        return
      }
      await this.fetchMatchSitution() // 匹配情况调接口
      this.$nextTick(async () => {
        await this.fetchScoreData() // 健康指数、雷达分析
        // 饼图（匹配情况）
        let color1 = ['#5B8FF9', '#BDD3FF']
        let matchData = chartsDataHandle(this.matchSituationData)
        let legendData1 = matchData.legendData
        let seriesData1 = matchData.seriesData
        let chartPie = this.$echarts.init(document.getElementById('pie-chart'))
        createPieChart(chartPie, color1, legendData1, seriesData1)
        chartPie.on('legendselectchanged', function(params) {
          // 饼图legend点击事件
          let selected = params.selected
          let name = params.name
          if (!selected[name]) {
            // 重复点击同一个legend，他的selected状态（true和false）会一直在切换
            selected[name] = true
          }
        })
      })
      // await this.chartSizeChange()
      this.$nextTick(async () => {
        // let size = this.ringChartStyle.width.split('px')[0]
        // let ringDom = document.getElementById('ring-chart')
        // let ringWidth = ringDom && ringDom.offsetWidth
        // let ringContainerDom = document.getElementsByClassName('ring-chart-container')[0]
        // let ringContainerWidth = ringContainerDom && ringContainerDom.offsetWidth
        // if (size !== ringWidth) {
        //   ringDom.style.marginLeft = (Math.abs(156 - size)) / 2 + 'px'
        // }
        await this.chartSizeChange()
        await this.radarChartSizeChange()
      })
      window.addEventListener('resize', async () => {
        await this.chartSizeChange()
        await this.radarChartSizeChange()
        this.radarChart && this.radarChart.resize()
        this.healthIndexChart && this.healthIndexChart.resize()
      })
    },
    // 筛选重置
    onFilterReset() {
      if (this.tableSearchObj.isFilter) {
        this.$refs.matchTable && this.$refs.matchTable.resetFilterHandle()
      }
    },
    // 查看历史版本
    onShowHistoryVersion() {
      this.isShowHistoryList = true
      this.$refs.historyRef.drawer = true
    },
    // 获取该机构最近一次的申请报告id
    async fetchLatestRecord() {
      let res = await getLatestRecord()
      if (res.code === 200) {
        this.applyId = res.data
        if (res.data === null || res.data === '' || res.data === undefined) {
          this.hasReport = false
        }
      }
    },
    // 获取健康指数、雷达分析
    async fetchScoreData() {
      // this.$nextTick(async () => {
      //   await this.chartSizeChange()
      // })
      let res = null
      if (this.dataFrom === 'self') {
        res = await getScoreApi(this.applyId)
      } else if(this.dataFrom === 'other') {
        res = await getPlatformScoreApi(this.applyId)
      }
      if (res.code === 200) {
        this.scoreDataObj = res.data || SCORE_DATA_OBJ
        const obj = {
          getvalue: [res.data.healthIndexScore],
          subtext: '健康指数'
        }
        let option = buildReportPieChart(obj.getvalue, obj.subtext)
        this.healthIndexChart = this.$echarts.init(document.getElementById('ring-chart'))
        this.healthIndexChart.setOption(option)
        // 雷达图
        this.radarChart = this.$echarts.init(document.getElementById('radar-chart'))
        let valueData = [this.scoreDataObj.riskItemScore, this.scoreDataObj.optimizationItemScore, this.scoreDataObj.dataMatchItemScore, this.scoreDataObj.missingDataItemScore]
        let radarOption = radarChart(valueData, '65%')
        this.radarChart.setOption(radarOption)
      }
    },
    // 未匹配原因组件传递的数据
    getNoMatchObj(data) {
      this.errorOptions = data
    },
    getmatchSitutionsObj(data) {
      // console.log(data)
    },
    // 匹配项审核报告模块表格传递的数据
    getMatchTable(data) {
      this.total = data.total
    },
    // 未匹配项审核报告模块表格传递的数据
    getNoMatchTable(data) {
      this.total = data.total
    },
    // 申请治理报告
    async onApplyReport() {
      // 还没有治理报告，立即申请
      // this.loading = true
      let res = null
      if (this.dataFrom === 'self') {
        res = await applyReportApi()
      }
      if (res.code === 200) {
        this.$message.success('申请治理报告成功')
        // this.loading = false
      } else {
        // this.loading = false
      }
    },
    // 匹配情况(匹配项、未匹配项)
    async fetchMatchSitution() {
      let res = null
      if (this.dataFrom === 'self') {
        res = await getMatchSituationApi(this.applyId)
      } else if(this.dataFrom === 'other') {
        res = await getPlatformMatchSituationApi(this.applyId)
      }
      if (res.code === 200) {
        this.matchSituationData = res.data
      }
    },
    // 饼图legend点击事件
    async onLegendClick(type) {
      this.tableSearchObj = this.$deepClone(NO_MATCH_TABLE_SEARCH_OBJ)
      if (type === 'match') {
        this.isSelectedMatch = true
        this.which_to_show = MathcItem
        this.searchTitle = '报告明细'
      } else {
        this.isSelectedMatch = false
        this.which_to_show = NoMatchItem
        this.searchTitle = '未匹配明细'
      }
    },
    // 点击历史版本的回调函数
    async onChangeVersion(applyId) {
      this.applyId = applyId
    },
    // 分页查询事件
    async getTableHandle() {
      if (!this.isSelectedMatch) {
        if (this.$refs.noMatchTable) {
          await this.$refs.noMatchTable.fetchData()
        }
      } else {
        if (this.$refs.matchTable) {
          await this.$refs.matchTable.fetchData()
        }
      }
    },
    // 点击查询按钮
    async onSearchReportTable() {
      this.tableSearchObj.pageNum = 1
      await this.getTableHandle()
    },
    // 只看问题字段
    async errorFilterChange(val) {
      this.tableSearchObj.errorFilterFlag = val ? '1' : ''
      this.tableSearchObj.pageNum = 1
      await this.getTableHandle()
    },
    // 是否筛选
    async isFilterChange(val) {
      if (!val) {
        this.tableSearchObj.filterList = []
        this.searchObj.pageNum = 1
        this.searchObj.filterErrorTypeList = []
        this.searchObj.filterRiskLevelList = []
        if (!this.isSelectedMatch) {
          if (this.$refs.noMatchTable) {
            await this.$refs.noMatchTable.fetchData()
          }
        } else {
          if (this.$refs.matchTable) {
            await this.$refs.matchTable.fetchData()
          }
        }
      }
    },
    // 放大
    onEnlarge() {
      this.enlarge = true
    },
    onEnNormal() {
      this.enlarge = false
    },
    // 数据来源弹窗
    onShowSource() {
      this.dialogVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
  [v-cloak] {
    display: none !important;
  }
.box-shadow-radius {
  box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
  border-radius: 4px;
}
.title-header {
  display: inline-block;
  width: 4px;
  height: 16px;
  /* background: #A9BBFF; */
  background-color: #0073E9;
  border-radius: 2px;
  vertical-align: middle;
}
.title-text {
  display: inline-block;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  margin-left: 5px;
  vertical-align: middle;
}
.icon-class {
  display: inline-block;
  position: absolute;
  right: 0px;
  transform: translateY(-50%);
  color: #2B60F8;
  cursor: pointer;
}
.chart-title {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  &:before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 16px;
    background: #A9BBFF;
    border-radius: 2px;
    transform: translateY(3px);
    margin-right: 5px;
  }
}
.el-table--border th.none-border, .el-table--border td.none-border {
  border-right: none !important;
}
.el-tabe td.none-border {
  border-right: none !important;
}
.el-main, .el-header {
  padding: 15px;
}
.insti-report-container {
  background-color: #EBEFF7;
  .insti-report-aside {
    background: #fff;
    padding: 10px !important;
    padding-right: 10px;
    margin-bottom: 0 !important;
    .health-index-section {
      /* height: 65%; */
      border-bottom: 1px solid #AFC8FA;
      display: flex;
      flex-direction: column;
      /* justify-content: space-between; */
      .title-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        .title-header {
          width: 4px;
          height: 18px;
          background: #0073E9;
          border-radius: 2px;
        }
      }
      .ring-chart-container {
        overflow: auto;
        margin-top: 30px;
        p {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
    .radar-section {
      padding: 10px 0 0 0px;
      position: relative;
      .title-header {
        background: #0073E9;
      }
      .radar-wrap {
        display: flex;
        width: 100%;
        justify-content: center;
      }
      .radar-chart {
        align-self: center;
        /* position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 40%); */
      }
    }
  }
  .insti-report-content-container {
    padding: 0 0 0 10px !important;
    .chart-container {
      /*图表容器*/
      height: 230px !important;
      margin-bottom: 10px;
      background-color: inherit;
      padding: 0 !important;
      display: flex;
      .match-item-section {
        width: 30%;
        /* width: auto; */
        max-width: 320px;
        margin-right: 10px;
        background: #fff;
        height: 100%;
        position: relative;
        padding: 10px 0 0 16px;
        .pie-legend-wrap {
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-30%);
          clear: both;
          overflow: hidden;
        }
        .pie-legend {
          /* width: 100%; */
          width: 80px;
          height: 38px;
          line-height: 38px;
          border-radius: 4px;
          border: 1px solid #C8D5FA;
          text-align: left;
          padding-left: 8px;
          float: right;
          &:hover {
            cursor: pointer;
          }
          &.active {
            border: 1px solid #638BF7;
            box-shadow: 0px 0px 6px 0px rgba(128, 161, 255, 0.8);
            .legend-text {
              color: #2B60F8;
            }
          }
          &.match-legend {
            margin-bottom: 10px;
          }
          .legend-circle {
            display: inline-block;
            width: 12px;
            height: 12px;
            background: #C2D6FF;
            border-radius: 6px;
            margin-right: 5px;
            vertical-align: middle;
            &.match-circle {
              background-color: #5B8FF9;
            }
          }
          .legend-text {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
            vertical-align: middle;
          }
        }
      }
      .no-match-item-section {
        background: #fff;
        height: 100%;
        flex: 1;
      }
      .apply-report-section {
        background-color: #fff;
        max-width: 200px;
        min-width: 150px;
        position: relative;
        .el-button {
          min-width: 120px;
          max-width: 140px;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          left: 50%;
        }
      }
    }
    .table-container {
      background: #fff;
      font-size: 12px !important;
      .report-table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        ::v-deep td {
          padding: 3px 5px !important;
        }
      }
      .search-form {
        .el-form-item {
          margin-bottom: 0;
        }
      }
    }
  }
}

.no-data-container {
  .no-data-main {
    padding: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    width: calc(100% + 40px);
    height: calc(100% + 24px);
    left: -20px;
    top: -12px;
    .none-data-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #fff;
      background-image: url('../../../assets/images/report-null-bg.png');
      background-repeat: no-repeat;
      /* background-position: center center; */
      background-size: cover;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .el-button {
        position: absolute;
        top: 35%;
        right: 5.3%;
        width: 240px;
        border-radius: 40px;
        padding: 20px;
        font-size: 24px;
        background-image: linear-gradient(179deg, #4B56F2 28%, #8141EA 100%);
        letter-spacing: 2px;
      }
      span {
        position: absolute;
        right: 5.3%;
        top: 23%;
        letter-spacing: 2px;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #888888;
      }
      .no-data-title {
        position: absolute;
        top: 10%;
        right: 5.3%;
        font-size: 42px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
  }
}
.enlarge-container {
  flex-direction: column;
  .report-table-header {
    /* display: flex;
    width: 100%;
    justify-content: space-between; */
    ::v-deep td {
      padding: 3px 5px !important;
    }
    .title-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      .title-header {
        width: 4px;
        height: 18px;
        background: #0073E9;
        border-radius: 2px;
        vertical-align: middle;
      }
    }
  }
}
</style>
