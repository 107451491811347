var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "no-match-container" }, [
      _c("div", { staticClass: "no-match-l title" }, [
        _c("span", { staticClass: "title-header" }),
        _c("h4", { staticClass: "title-text" }, [_vm._v(" 未匹配原因 ")]),
      ]),
      _c("div", {
        staticClass: "no-match-c no-match-bar-wrap",
        attrs: { id: "no-match-bar" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }